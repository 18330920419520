<template>
  <ErrorMessages v-if="store.errors.length" :errors="store.errors" />
  <ErrorMessages v-if="validationErrors.length" :errors="validationErrors" />

  <div class="flex flex-row items-center justify-end gap-2">
    <SuccessLabel
      v-if="!store.isSaving && store.isSavedSuccessfully"
      :label="t('vacancy.saved')"
      class="mx-4"
    />
    <button
      :disabled="isSaveDisabled"
      type="submit"
      class="inline-flex items-center rounded-md px-4 py-2 text-base font-medium shadow-xs focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
      :class="{
        'cursor-not-allowed opacity-50': isSaveDisabled,
        'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50':
          nextTab,
        'border border-transparent bg-indigo-600 text-white hover:bg-indigo-700':
          !nextTab,
      }"
      @click="onSave"
    >
      {{ t('vacancy.save') }}
      <LoadingSpinner v-if="store.isSaving" class="ml-3 h-4 w-4" />
    </button>
    <button
      v-if="nextTab"
      :disabled="isSaveDisabled"
      type="submit"
      class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-xs hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
      :class="{ 'cursor-not-allowed opacity-50': isSaveDisabled }"
      @click="onSaveAndNext"
    >
      {{ t('saveAndNext') }}
      <LoadingSpinner v-if="store.isSaving" class="ml-3 h-4 w-4 text-white" />
    </button>
  </div>
</template>

<script setup>
import ErrorMessages from './ErrorMessages.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import SuccessLabel from './SuccessLabel.vue'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSiteStore } from '../store/siteStore'
import { usePublisherStore } from '../store/publisherStore'
import { useCompanyStore } from '../store/companyStore'
import { useVacancyStore } from '../store/vacancyStore'
import { useSubscriptionStore } from '../store/subscriptionStore'
import { useSalaryScaleGroupStore } from '../store/salaryScaleGroupStore'
import { useRouter, useRoute } from 'vue-router'
import { useFlowStore } from '../store/flowStore'

const props = defineProps({
  validationErrors: {
    type: Array,
    required: true,
  },
  validationTouch: {
    type: [Function, null],
    required: true,
  },
  storeName: {
    type: String,
    required: true,
  },
  storeAction: {
    type: String,
    required: true,
  },
  nextTab: {
    type: String,
    default: null,
  },
  additionalParams: {
    type: Object,
    default: null,
  },
})

let store
if (props.storeName === 'site') {
  store = useSiteStore()
} else if (props.storeName === 'publisher') {
  store = usePublisherStore()
} else if (props.storeName === 'company') {
  store = useCompanyStore()
} else if (props.storeName === 'vacancy') {
  store = useVacancyStore()
} else if (props.storeName === 'flow') {
  store = useFlowStore()
} else if (props.storeName === 'salaryScaleGroup') {
  store = useSalaryScaleGroupStore()
} else if (props.storeName === 'subscription') {
  store = useSubscriptionStore()
}

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const router = useRouter()
const route = useRoute()

const isSaveDisabled = computed(
  () =>
    store.isLoading ||
    store.isSaving ||
    store.saveDisabled ||
    props.validationErrors.length,
)

const onSave = async () => {
  if (props.validationTouch) {
    await props.validationTouch()
  }

  if (!props.validationErrors.length) {
    props.additionalParams
      ? await store[props.storeAction](props.additionalParams)
      : await store[props.storeAction]()
  }
}

const onSaveAndNext = async () => {
  await onSave()
  if (store.isSavedSuccessfully && !store.isSaving) {
    router.push({ name: props.nextTab, params: { id: route.params.id } })
  }
}

let timer
watch(
  () => store.isSavedSuccessfully,
  (next) => {
    // from false to true
    if (next) {
      timer = setTimeout(() => {
        store.isSavedSuccessfully = false
      }, 5000)
    } else {
      clearTimeout(timer)
    }
  },
)
</script>

<template>
  <div class="grid grid-cols-1 gap-6 lg:grid-cols-6">
    <div class="col-span-3">
      <div>
        <label for="minHours" class="block text-sm font-medium text-gray-700">{{
          t('minHours')
        }}</label>
        <div class="relative mt-1 rounded-md shadow-xs">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <ClockIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="minHours"
            type="number"
            name="minHours"
            :min="0"
            :value="hours.min || hours.exact || 0"
            class="hide-arrows block w-full rounded-md border-gray-300 pr-12 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            aria-describedby="price-currency"
            @change="changeHoursMin"
          />
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <span id="price-currency" class="text-gray-500 sm:text-sm">
              {{ t('perWeek') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-3">
      <div>
        <label for="maxHours" class="block text-sm font-medium text-gray-700">{{
          t('maxHours')
        }}</label>
        <div class="relative mt-1 rounded-md shadow-xs">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <ClockIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="maxHours"
            type="number"
            name="maxHours"
            :min="0"
            :value="hours.max || hours.exact || 0"
            class="hide-arrows block w-full rounded-md border-gray-300 pr-12 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            aria-describedby="price-currency"
            @change="changeHoursMax"
          />
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <span id="price-currency" class="text-gray-500 sm:text-sm">
              {{ t('perWeek') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useVacancyStore } from '../../store/vacancyStore'
import { ClockIcon } from '@heroicons/vue/24/outline'

const vacancyStore = useVacancyStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const hours = computed(() => vacancyStore.vacancy.employmentHours)

const changeHoursMin = (e) => {
  const input = parseFloat(e.target.value)

  const max = hours.value.exact || hours.value.max
  if (input === hours.value.max) {
    vacancyStore.vacancy.employmentHours = { exact: input }
  } else {
    vacancyStore.vacancy.employmentHours = {
      min: input,
      max: Math.max(input, max),
    }
  }
}
const changeHoursMax = (e) => {
  const input = parseFloat(e.target.value)
  const min = hours.value.exact || hours.value.min
  if (input === min) {
    vacancyStore.vacancy.employmentHours = { exact: input }
  } else {
    vacancyStore.vacancy.employmentHours = {
      min: Math.min(min, input),
      max: input,
    }
  }
}
</script>

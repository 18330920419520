<template>
  <label v-if="label" class="block text-sm font-medium text-gray-700">
    {{ label }}
    <span class="text-gray-400">({{ modelValue.length }})</span>
  </label>
  <div>
    <draggable
      v-model="carouselImages"
      class="grid grid-cols-12 gap-2"
      group="images"
      tag="div"
      itemKey="index"
      filter=".item"
    >
      <template #item="{ element, index }">
        <div class="col-span-6 lg:col-span-4 xl:col-span-3">
          <div v-if="element">
            <FormImage
              :id="`carousel-image-${index}`"
              :isCarouselImage="true"
              :imageUrl="getCarouselImgUrl(element)"
              :rules="imageRules"
              @discard-image="deleteCarouselImage(index)"
            />
          </div>
          <div v-else class="carousel_item item">
            <FormImage
              id="carouselInsert"
              :rules="imageRules"
              :recommendation="t('carousel.recommendation')"
              :multiple="true"
              :isCarouselImage="true"
              @imagechange="addCarouselImages"
            />
          </div></div
      ></template>
    </draggable>
  </div>
  <button
    v-if="showMoreButton"
    type="button"
    class="mt-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm leading-4 font-medium text-indigo-700 hover:bg-indigo-200 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
    @click="showMore = !showMore"
  >
    {{ showMore ? 'Show less' : 'Show all' }}
  </button>
</template>

<script setup>
import { computed, ref } from 'vue'

import draggable from 'vuedraggable'
import { useI18n } from 'vue-i18n'
import FormImage from './FormImage.vue'
import { getImgSrc } from '../utils/imgSrc'

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
})
const emit = defineEmits(['update:modelValue'])

const showMoreButton = computed(() => props.modelValue.length > 7)
const showMore = ref(false)

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const imageRules = {
  $autoDirty: true,
}

const carouselImages = computed({
  get() {
    const items = ['', ...props.modelValue]
    return showMore.value ? items : items.slice(0, 8)
  },
  set(newOrder) {
    const shownImages = newOrder.slice(1) // First index in the list is the upload image component
    const additionalImages = props.modelValue.slice(shownImages.length)
    const imagesDuplicate = [...shownImages, ...additionalImages]
    emit('update:modelValue', imagesDuplicate)
  },
})
const getCarouselImgUrl = (image) => {
  if (typeof image === 'string') {
    return getImgSrc({
      imgUrl: image,
      height: 175,
    })
  } else {
    return URL.createObjectURL(image)
  }
}
const addCarouselImages = async (images) => {
  showMore.value = true
  const imagesDuplicate = carouselImages.value.slice(1) // First index in the list is the upload image component

  //Can't use 'forEach' here because images is a FileList, not a regular array
  for (let i = 0; i < images.length; i++) {
    imagesDuplicate.push(images[i])
  }
  emit('update:modelValue', imagesDuplicate)
}
const deleteCarouselImage = (index) => {
  const shownImages = carouselImages.value.slice(1) // First index in the list is the upload image component
  const additionalImages = props.modelValue.slice(shownImages.length)
  const imagesDuplicate = [...shownImages, ...additionalImages]
  imagesDuplicate.splice(index - 1, 1)
  emit('update:modelValue', imagesDuplicate)
}
</script>

<style></style>

<template>
  <button
    type="button"
    class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm leading-4 font-medium text-gray-700 shadow-xs hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-hidden"
    :class="{ 'bg-gray-100': isActionActive }"
  >
    <ClipboardDocumentListIcon class="mr-3 -ml-1 h-5 w-5" aria-hidden="true" />
    Input
  </button>
</template>

<script setup>
import { ClipboardDocumentListIcon } from '@heroicons/vue/24/outline'

const isActionActive = false
</script>

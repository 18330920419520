<template>
  <div class="col-span-6">
    <div class="col-span-6">
      <label
        :for="`${siteId}${tagGroup.id}`"
        :set="(formatedGroup = formatGroupName())"
        class="flex items-center gap-x-1 text-sm font-medium text-gray-700"
        >{{ t(`vacancy.publication.tagGroups.${formatedGroup.category}`) }}
        <span class="text-xs text-gray-400">{{
          `(${formatedGroup.name})`
        }}</span>
      </label>
    </div>
    <Multiselect
      :id="`${siteId}${tagGroup.id}`"
      :maxHeight="400"
      :modelValue="selectedTags"
      :options="tagOptions"
      :searchable="true"
      class="mt-1 block w-full rounded-md border-gray-300 shadow-xs focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      mode="tags"
      @change="(e) => updateTags(e)"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useVacancyStore } from '../../store/vacancyStore'
import { computed } from 'vue'

import Multiselect from '@vueform/multiselect'

const props = defineProps(['tagGroup', 'siteId', 'selectedTags'])

const vacancyStore = useVacancyStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const tagOptions = computed(() => {
  return props.tagGroup.tags.map((t) => ({ value: t.key, label: t.label }))
})

const updateTags = (tags) => {
  const publication = vacancyStore.publications.find(
    (p) => p.siteId === props.siteId,
  )
  publication.tags[props.tagGroup.id] = tags
}
const formatGroupName = () => {
  const categoryPos = props.tagGroup.name.indexOf('VACANCY')
  return {
    name:
      categoryPos === -1
        ? props.tagGroup.name
        : props.tagGroup.name.slice(0, categoryPos - 1),
    category: props.tagGroup.tagCategory,
  }
}
</script>

<template>
  <div class="bg-white px-4 py-5 sm:p-6">
    <div class="grid grid-cols-4 gap-4 md:grid-cols-8">
      <div class="col-span-4 md:col-span-8">
        <label
          for="locationSearch"
          class="sr-only block text-sm font-medium text-gray-700"
          >{{ t('vacancy.location.address') }}</label
        >
        <div>
          <Multiselect
            id="locationSearch"
            v-model="locationSearch"
            :placeholder="t('vacancy.location.searchHint')"
            :caret="false"
            :filterResults="false"
            :minChars="4"
            :resolveOnLoad="false"
            :delay="100"
            :searchable="true"
            :options="
              async function (query) {
                return await searchLocation(query, language)
              }
            "
            class="block w-full rounded-md border-gray-300 shadow-xs focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            @change="(e) => updateLocation(e)"
          />
        </div>
      </div>
    </div>

    <ErrorMessage
      v-if="locationError"
      :error="locationError"
      class="mt-4 mb-0"
    />
  </div>
</template>

<script setup>
import Multiselect from '@vueform/multiselect'
import { useI18n } from 'vue-i18n'
import { ref, computed, watch } from 'vue'
import { searchLocation } from '../utils/location'
import { useVacancyStore } from '../store/vacancyStore'
import { useCompanyStore } from '../store/companyStore'
import { useSettingsStore } from '../store/settingsStore'
import regionMapping from '../utils/regionMapping.js'
import ErrorMessage from './ErrorMessage.vue'

const props = defineProps({
  storeName: {
    type: String,
    required: true,
  },
})

let store
if (props.storeName === 'vacancy') {
  store = useVacancyStore()

  // Clear search input if the vacancy's address is the different than the one in the input
  watch(
    () => store.vacancy?.location,
    (newLocation) => {
      if (
        locationSearch.value?.address?.postcode !== newLocation.zipCode ||
        locationSearch.value?.address?.road != newLocation.street
      ) {
        locationSearch.value = ''
      }
    },
  )
} else if (props.storeName === 'company') {
  store = useCompanyStore()
}

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const locationSearch = ref('')
const locationError = ref(null)
const language = computed(() => useSettingsStore().locale)

const updateLocation = (newLocation) => {
  if (newLocation) {
    store[props.storeName].location = {
      zipCode: newLocation.address.postcode || '',
      city: newLocation.address.city || '',
      region: regionMapping[newLocation.address.state] || '',
      countryCode: newLocation.address.country_code.toUpperCase(),
      geo: { lat: newLocation.lat, lng: newLocation.lon },
      street: newLocation.address.road || '',
      houseNumber: newLocation.address.house_number || '',
    }
  }
}
</script>

<template>
  <div class="px-6 py-2 text-sm">
    <Disclosure v-slot="{ open }" as="div">
      <div>
        <DisclosureButton
          class="flex w-full items-center text-left text-gray-800"
        >
          <label class="ml-3 cursor-pointer font-medium">{{
            t('candidateGateway.sendEmailStep.availableFields') + ':'
          }}</label>
          <span class="ml-2 flex h-4 items-center">
            <ChevronDownIcon
              :class="[open ? '-rotate-180' : 'rotate-0', 'h-4 w-4 transform']"
              aria-hidden="true"
            />
          </span>
        </DisclosureButton>
      </div>
      <DisclosurePanel as="div" class="mt-2 flex flex-wrap gap-y-2">
        <button
          v-for="(field, fieldKey) in candidateFields"
          :key="fieldKey"
          :title="field.label"
          class="ml-2 inline-flex items-center rounded-sm px-2 py-0.5 text-xs font-medium"
          :class="{
            'bg-indigo-100 text-indigo-800': field.isUsed,
            'bg-gray-100 text-gray-800': !field.isUsed,
          }"
          @click="insertTag('candidate.' + fieldKey)"
        >
          <svg
            v-show="field.isUsed"
            class="mr-1.5 h-2 w-2 text-indigo-400"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
          {{ 'candidate.' + fieldKey }}
        </button>
        <div v-for="(field, index) in customFields" :key="index">
          <button
            :title="field.question"
            class="ml-2 inline-flex items-center rounded-sm bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800"
            @click="
              insertTag(`candidate.customFields.${field.reference}.question`)
            "
          >
            {{ `candidate.${field.reference}.question` }}
          </button>
          <button
            class="ml-2 inline-flex items-center rounded-sm bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800"
            @click="insertTag(`candidate.${field.reference}.answer`)"
          >
            {{ `candidate.${field.reference}.answer` }}
          </button>
        </div>

        <button
          v-for="(field, fieldKey) in metaFields?.page"
          :key="fieldKey"
          :title="field.label"
          class="ml-2 inline-flex items-center rounded-sm bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800"
          @click="insertTag('candidate.metadata.page.' + fieldKey)"
        >
          {{ `meta.page.${fieldKey}` }}
        </button>
        <button
          v-for="(field, fieldKey) in metaFields?.firstTouchUtm"
          :key="fieldKey"
          :title="field.label"
          class="ml-2 inline-flex items-center rounded-sm bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800"
          @click="insertTag('candidate.metadata.firstTouchUtm.' + fieldKey)"
        >
          {{ `meta.firstTouchUtm.${fieldKey}` }}
        </button>
        <button
          v-for="(field, fieldKey) in metaFields?.lastTouchUtm"
          :key="fieldKey"
          :title="field.label"
          class="ml-2 inline-flex items-center rounded-sm bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800"
          @click="insertTag('candidate.metadata.lastTouchUtm.' + fieldKey)"
        >
          {{ `meta.lastTouchUtm.${fieldKey}` }}
        </button>
        <button
          v-if="metaFields?.referrer"
          class="ml-2 inline-flex items-center rounded-sm bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800"
          @click="insertTag('candidate.metadata.referrer')"
        >
          {{ `meta.referrer` }}
        </button>

        <button
          v-for="(field, fieldKey) in flowStore.availableFields.vacancy"
          :key="fieldKey"
          :title="field.label"
          class="ml-2 inline-flex items-center rounded-sm bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800"
          @click="insertTag('vacancy.' + fieldKey)"
        >
          {{ 'vacancy.' + fieldKey }}
        </button>
        <button
          v-for="(field, fieldKey) in flowStore.availableFields.company"
          :key="fieldKey"
          :title="field.label"
          class="ml-2 inline-flex items-center rounded-sm bg-purple-100 px-2 py-0.5 text-xs font-medium text-purple-800"
          @click="insertTag('company.' + fieldKey)"
        >
          {{ 'company.' + fieldKey }}
        </button>
        <button
          v-for="(field, fieldKey) in flowStore.availableFields.site"
          :key="fieldKey"
          :title="field.label"
          class="ml-2 inline-flex items-center rounded-sm bg-rose-100 px-2 py-0.5 text-xs font-medium text-rose-800"
          @click="insertTag('site.' + fieldKey)"
        >
          {{ 'site.' + fieldKey }}
        </button>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import { useFlowStore } from '../../store/flowStore'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const emit = defineEmits(['onTagClick'])

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const flowStore = useFlowStore()
const insertTag = (tag) => {
  emit('onTagClick', tag)
}
const candidateFields = computed(() => {
  if (!flowStore.availableFields?.candidate) {
    return []
  }
  return Object.fromEntries(
    Object.entries(flowStore.availableFields.candidate).filter(
      ([key]) => key !== 'customFields' && key !== 'metadata',
    ),
  )
})
const customFields = computed(() => {
  if (!flowStore.availableFields?.candidate) {
    return []
  }
  const fields = Object.fromEntries(
    Object.entries(flowStore.availableFields.candidate).filter(
      ([key]) => key == 'customFields',
    ),
  )
  return fields.customFields
})
const metaFields = computed(() => {
  if (!flowStore.availableFields?.candidate) {
    return []
  }
  const fields = Object.fromEntries(
    Object.entries(flowStore.availableFields.candidate).filter(
      ([key]) => key == 'metadata',
    ),
  )
  return fields.metadata
})
</script>

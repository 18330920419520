<template>
  <div class="flex items-center">
    <label :for="id" class="block text-sm font-medium text-gray-700">{{
      label
    }}</label>
    <HelpPopover v-if="helpPopoverText" :text="helpPopoverText" />
  </div>

  <div class="relative mt-1 rounded-md shadow-xs">
    <div
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
    >
      <slot name="inputPrefix"></slot>
    </div>
    <input
      :id="id"
      :type="type"
      :name="id"
      :disabled="disabled"
      :placeholder="placeholder || ''"
      class="mt-1 block w-full rounded-md shadow-xs focus:outline-hidden sm:text-sm"
      :class="[
        v$[id].$error
          ? 'border-red-300 pr-10 text-red-900 focus:border-red-500 focus:ring-red-500'
          : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
        inputClass,
      ]"
      :value="value"
      :aria-invalid="v$[id].$error"
      :aria-describedby="v$[id].$error ? `${id}-error` : ''"
      @input="(e) => onInput(e.target.value, inputParams)"
    />
    <div
      v-if="v$[id].$error"
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
    >
      <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
    </div>
    <div
      v-if="secretPreviewEnabled && value"
      class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
      @click="showHideSecret"
    >
      <EyeIcon
        v-if="type === 'password'"
        class="h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
      <EyeSlashIcon v-else class="h-5 w-5 text-gray-400" aria-hidden="true" />
    </div>
  </div>
  <div v-if="v$[id].$error">
    <p
      v-for="error in v$[id].$errors"
      :id="`${id}-error`"
      :key="error.$uid"
      class="mt-2 text-sm text-red-600"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/vue/20/solid'
import HelpPopover from './HelpPopover.vue'

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  onInput: {
    type: Function,
    required: true,
  },
  secretPreviewEnabled: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [String, Number],
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  inputClass: {
    type: String,
    default: '',
  },
  rules: {
    type: Object,
    default: () => {},
  },
  helpPopoverText: {
    type: String,
    default: '',
  },
  inputParams: {
    type: [Object, null],
    default: null,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const emit = defineEmits(['showSecret'])

const rules = computed(() => ({
  [props.id]: props.rules,
}))

const v$ = useVuelidate(rules, {
  [props.id]: computed(() => props.value),
})

const showHideSecret = () => {
  emit('showSecret', { inputName: props.id })
}
</script>

<template>
  <div class="mb-4 rounded-md bg-yellow-50 p-3">
    <div class="flex">
      <div class="shrink-0">
        <ExclamationTriangleIcon
          class="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <div class="text-sm text-yellow-700">
          <p>
            {{ t('site.customCodeWarning') }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="siteStore.site.id" class="grid grid-cols-6 items-start gap-6">
    <div class="col-span-6">
      <label class="mb-1 block text-sm font-medium text-gray-700">{{
        t('site.customHeaderLabel')
      }}</label>
      <CodeEditor v-model="siteStore.site.customHeaderCode" />
      <p class="mt-0.5 text-xs text-gray-500">
        {{ t('site.customHeaderDescription') }}
      </p>
    </div>
    <div class="col-span-6">
      <label class="mb-1 block text-sm font-medium text-gray-700">{{
        t('site.customFooterLabel')
      }}</label>
      <CodeEditor v-model="siteStore.site.customFooterCode" />
      <p class="mt-0.5 text-xs text-gray-500">
        {{ t('site.customFooterDescription') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { useSiteStore } from '../../store/siteStore'
import { useI18n } from 'vue-i18n'
import CodeEditor from '../CodeEditor.vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'

const siteStore = useSiteStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
</script>

<template>
  <div
    id="tab-navigation"
    class="border-gray-200 sm:pt-0 sm:pb-0"
    :class="{
      'border-b pb-5': linePosition === 'bottom',
      'border-t pt-5': linePosition === 'top',
    }"
  >
    <div
      :class="{
        'mt-3 sm:mt-4': linePosition === 'bottom',
      }"
    >
      <div class="sm:hidden">
        <label for="current-tab" class="sr-only">{{ t('selectTab') }}</label>
        <select
          id="current-tab"
          name="current-tab"
          class="block w-full rounded-md border-gray-300 py-2 pr-10 pl-3 text-base focus:border-indigo-500 focus:ring-indigo-500 focus:outline-hidden sm:text-sm"
          :value="activeTab"
          @change="(e) => setActiveTab(e.target.value)"
        >
          <option
            v-for="tab in tabs"
            :key="tab"
            :selected="isActiveTab(tab)"
            :value="tab"
          >
            {{ t(`${storeName}.tabs.${tab}`) }}
          </option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="-mb-px flex space-x-8">
          <button
            v-for="tab in tabs"
            :key="tab"
            class="rounded-md px-1 py-4 text-sm font-medium whitespace-nowrap focus:outline-hidden focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-indigo-500"
            :class="{
              'rounded-b-none border-b-2': linePosition === 'bottom',
              'rounded-t-none border-t-2': linePosition === 'top',
              'border-indigo-500 text-indigo-600': isActiveTab(tab),
              'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
                !isActiveTab(tab),
            }"
            :aria-current="isActiveTab(tab) ? 'page' : undefined"
            @click="setActiveTab(tab)"
          >
            {{ t(`${storeName}.tabs.${tab}`) }}
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'

const props = defineProps({
  linePosition: {
    type: String,
    default: 'bottom',
    validator(value) {
      // The value must match one of these strings
      return ['top', 'bottom'].includes(value)
    },
  },
  storeName: {
    type: String,
    default: '',
  },
  tabs: {
    type: Array,
    required: true,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const route = useRoute()
const router = useRouter()
const activeTab = computed(() => route.name)
const setActiveTab = (tab) => {
  router.push({ name: tab, params: { id: route.params.id } })
}
const isActiveTab = (currentTab) => {
  if (
    (activeTab.value === 'candidateDetail' && currentTab === 'candidates') ||
    (activeTab.value === 'tagDetail' && currentTab === 'tags')
  ) {
    return true
  }
  return currentTab === activeTab.value
}
</script>

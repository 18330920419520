<template>
  <TwoColumnForm :title="t('company.importer.configHeader')" class="relative">
    <div
      v-if="importerStore.configs.length"
      class="top-10 left-0 mb-6 w-72 md:absolute"
    >
      <button
        type="button"
        class="inline-flex items-center rounded-md border border-indigo-600 bg-transparent px-4 py-2 text-sm font-medium text-indigo-600 shadow-xs hover:bg-indigo-700 hover:text-white focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
        :disabled="
          importerStore.isLoading ||
          importerStore.isSaving ||
          !importerStore.activeConfig.id
        "
        @click="importerStore.addConfig()"
      >
        {{ t('company.importer.addExtraConfig') }}
      </button>

      <div
        v-if="importerStore.configs.length > 1"
        class="mt-2 flex flex-col md:mt-8"
      >
        <p class="mb-2 text-xs font-medium">
          {{ t('company.importer.existingConfigs') }}
        </p>
        <button
          v-for="(config, index) in availableConfigs"
          :key="config.id"
          class="flex items-center justify-between rounded-md px-2 py-1 text-left text-sm"
          :class="{
            'bg-indigo-100': config.id === importerStore.activeConfig.id,
          }"
          :disabled="importerStore.isLoading || importerStore.isSaving"
          @click="importerStore.changeActiveConfig(index)"
        >
          <span :class="{ italic: !config.id }">{{ config.label }}</span>
          <span
            v-show="config.id === importerStore.activeConfig.id"
            class="hidden md:inline"
            >&gt;</span
          >
        </button>
      </div>
    </div>

    <TwoColumnFormCard>
      <div v-if="importerStore.activeConfig.id" class="flex justify-end">
        <label
          for="archive"
          class="mr-2 block text-sm font-medium text-gray-700"
          >{{ t('archived') }}</label
        >
        <Toggle
          srLabel="archive"
          :checked="importerStore.activeConfig.archived"
          :onToggle="onToggle"
        />
      </div>
      <div class="grid grid-cols-6 gap-6">
        <ImporterAtsSelectbox />
        <ImporterSourceOfTruthSelectbox v-if="importerStore.activeConfig.ats" />
        <ImporterTriggerTypeSelectbox v-if="importerStore.activeConfig.ats" />
        <ImporterCronIntervalSelectbox
          v-if="importerStore.activeConfig?.triggerType === 'SCHEDULE'"
        />
        <ImporterCutoffDatePicker v-if="importerStore.activeConfig.ats" />

        <ConnexysImporter
          v-if="importerStore.activeConfig.ats === 'connexys'"
          v-model="importerStore.activeConfig.customization"
        />
        <RecruiteeImporter
          v-else-if="importerStore.activeConfig.ats === 'recruitee'"
          v-model="importerStore.activeConfig.customization"
        />
        <SolutionsFactoryImporter
          v-else-if="importerStore.activeConfig.ats === 'solutions-factory'"
          v-model="importerStore.activeConfig.customization"
        />

        <div v-if="importerStore.activeConfig.ats" class="col-span-6">
          <label
            for="importerSites"
            class="text-sm font-medium text-gray-700"
            >{{ t('company.importer.sites') }}</label
          >

          <Multiselect
            id="importerSites"
            ref="multiselect"
            :modelValue="importerStore.activeConfig.targetSites"
            :max-height="400"
            :options="searchSite"
            :filterResults="false"
            :noOptionsText="t('vacancy.company.noOptions')"
            :resolveOnLoad="true"
            :minChars="0"
            :delay="500"
            :searchable="true"
            :object="true"
            valueProp="id"
            label="name"
            trackBy="name"
            inputType="search"
            autocomplete="off"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-xs focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            mode="tags"
            @change="(e) => updateSites(e)"
            ><template v-if="v$.sites.$error" #caret>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
              >
                <ExclamationCircleIcon
                  class="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            </template>
          </Multiselect>

          <div v-if="v$.sites.$errors">
            <p
              v-for="(error, index) in v$.sites.$errors"
              id="importer-sites-error"
              :key="index"
              class="mt-2 text-sm text-red-600"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>

        <ErrorMessages
          v-if="importerStore.errors.length"
          :errors="importerStore.errors"
          class="col-span-6"
        />
        <SuccessMessage
          v-if="successMessage"
          :success="successMessage"
          class="col-span-6"
        />

        <div
          v-if="importerStore.activeConfig.ats"
          class="col-span-6 ml-auto flex flex-row-reverse items-center gap-4"
        >
          <button
            type="button"
            class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-xs hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
            :disabled="importerStore.isLoading || importerStore.isSaving"
            @click="save()"
          >
            {{ t('apply') }}
            <LoadingSpinner
              v-if="importerStore.isSaving"
              class="ml-3 h-4 w-4 text-white"
            />
          </button>

          <button
            type="button"
            class="inline-flex items-center rounded-md border border-indigo-600 bg-white px-4 py-2 text-base font-medium text-indigo-600 shadow-xs hover:bg-indigo-700 hover:text-white focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
            :disabled="importerStore.isLoading || importerStore.isSaving"
            @click="testConfiguration()"
          >
            {{ t('company.importer.testConfig') }}
          </button>

          <SuccessLabel
            v-if="importerStore.isSavedSuccessfully"
            :label="t('vacancy.saved')"
            class=""
          />
          <div v-if="importerStore.isLoading" class="flex items-center gap-x-2">
            <LoadingSpinner class="ml-3 h-4 w-4 text-indigo-600" />
            <span>{{ t('running') }}</span>
          </div>
        </div>
      </div>
    </TwoColumnFormCard>
  </TwoColumnForm>

  <div v-if="importerStore.selectedEvent && importerStore.selectedEvent?.mock">
    <h3 class="mt-8 text-lg leading-6 font-medium text-gray-900">
      {{ t('company.importer.testResults') }}
    </h3>
    <EventDetail />
  </div>
</template>

<script setup>
import ImporterAtsSelectbox from './ImporterAtsSelectbox.vue'
import ImporterTriggerTypeSelectbox from './ImporterTriggerTypeSelectbox.vue'
import ImporterCronIntervalSelectbox from './ImporterCronIntervalSelectbox.vue'
import ImporterCutoffDatePicker from './ImporterCutoffDatePicker.vue'
import ImporterSourceOfTruthSelectbox from './ImporterSourceOfTruthSelectbox.vue'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'
import Multiselect from '@vueform/multiselect'
import TwoColumnForm from '../TwoColumnForm.vue'
import TwoColumnFormCard from '../TwoColumnFormCard.vue'
import LoadingSpinner from '../LoadingSpinner.vue'
import Toggle from '../Toggle.vue'
import SuccessLabel from '../SuccessLabel.vue'
import SuccessMessage from '../SuccessMessage.vue'
import ErrorMessages from '../ErrorMessages.vue'
import ConnexysImporter from './ConnexysImporter.vue'
import RecruiteeImporter from './RecruiteeImporter.vue'
import SolutionsFactoryImporter from './SolutionsFactoryImporter.vue'
import EventDetail from './EventDetail.vue'
import { useImporterStore } from '../../store/importerStore'
import tooManagement from '../../api/tooManagement'

const importerStore = useImporterStore()
const route = useRoute()
const companyId = route.params.id

importerStore.loadConfig(companyId)

importerStore.page.number = 1
importerStore.loadEvents(companyId)

const sites = computed(() => importerStore.activeConfig.targetSites)
const availableConfigs = computed(() =>
  importerStore.configs.map((config) => {
    let label = t('company.importer.unsavedConfig')
    if (config.id) {
      label = importerStore.availableATS.find(
        (item) => item.value === config.ats,
      ).label
    }
    return { ...config, label }
  }),
)
const successMessage = ref('')

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const v$ = useVuelidate(
  {
    sites: {},
  },
  { sites },
)

const save = async () => {
  v$.value.$touch()
  successMessage.value = ''

  if (!v$.value.$invalid) {
    await importerStore.saveConfig(companyId)
    if (!importerStore.activeConfig?.archived) {
      await importerStore.executeImport(companyId)
    }
    if (!importerStore.selectedEvent.error) {
      successMessage.value = t('company.importer.importFinished')
    }
  }
}

const testConfiguration = async () => {
  v$.value.$touch()
  successMessage.value = ''

  if (!v$.value.$invalid) {
    await importerStore.testAtsConfiguration(companyId)
  }
}

const searchSite = async (filter = '') => {
  const queryRes = await tooManagement.getSites({
    filter: filter || '',
    size: 15,
  })
  return queryRes.data
}

const updateSites = (newSites) => {
  //Used for validating the UI
  sites.value = newSites
  importerStore.activeConfig.targetSites = newSites
}

const onToggle = () => {
  importerStore.activeConfig.archived = !importerStore.activeConfig.archived
}
</script>

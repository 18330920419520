<template>
  <div
    v-if="candidateStore.candidate.metadata"
    class="mt-5 overflow-hidden bg-white shadow-sm sm:rounded-lg"
  >
    <div
      class="flex px-4 py-5 hover:cursor-pointer sm:px-5"
      @click="$refs.collapseButton.click()"
    >
      <h3 class="grow text-lg leading-6 font-medium text-gray-900">
        {{ t('candidates.meta.title') }}
      </h3>
      <button
        ref="collapseButton"
        class="text-indigo-500 focus:outline-hidden focus-visible:ring-2 focus-visible:ring-indigo-500"
        @click.stop="collapsePanel = !collapsePanel"
      >
        <ChevronDoubleUpIcon
          class="w-5 transition"
          :class="collapsePanel ? 'rotate-180' : ''"
        />
      </button>
    </div>
    <div
      class="border-t border-gray-200 break-words transition-[max-height] duration-500"
      :class="!collapsePanel ? 'h-fit max-h-screen' : 'h-0 max-h-0'"
    >
      <dl class="sm:divide-y sm:divide-gray-200">
        <div
          v-if="
            notEmpty(candidateStore.candidate.metadata.page) ||
            candidateStore.candidate.metadata.referrer
          "
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <span class="text-sm font-medium text-gray-500">
            {{ t('candidates.meta.page.label') }}</span
          >

          <div class="flex flex-col sm:col-span-2 sm:gap-4">
            <div
              v-if="candidateStore.candidate.metadata.page.title"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.page.title') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                {{ candidateStore.candidate.metadata.page.title }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.referrer"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.referrer') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.referrer }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.page.url"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.page.url') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.page.url }}
              </dd>
            </div>
          </div>
        </div>

        <div
          v-if="notEmpty(candidateStore.candidate.metadata.firstTouchUtm)"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <span class="text-sm font-medium text-gray-500">
            {{ t('candidates.meta.utm.firstTouch') }}</span
          >

          <div class="sm:col-span-2 sm:grid sm:grid-cols-2 sm:gap-4">
            <div
              v-if="candidateStore.candidate.metadata.firstTouchUtm.campaign"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.campaign') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.firstTouchUtm.campaign }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.firstTouchUtm.source"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.source') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.firstTouchUtm.source }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.firstTouchUtm.medium"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.medium') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.firstTouchUtm.medium }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.firstTouchUtm.term"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.term') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.firstTouchUtm.term }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.firstTouchUtm.content"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.content') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.firstTouchUtm.content }}
              </dd>
            </div>
          </div>
        </div>

        <div
          v-if="notEmpty(candidateStore.candidate.metadata.lastTouchUtm)"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <span class="text-sm font-medium text-gray-500">
            {{ t('candidates.meta.utm.lastTouch') }}</span
          >

          <div class="sm:col-span-2 sm:grid sm:grid-cols-2 sm:gap-4">
            <div
              v-if="candidateStore.candidate.metadata.lastTouchUtm.campaign"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.campaign') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.lastTouchUtm.campaign }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.lastTouchUtm.source"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.source') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.lastTouchUtm.source }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.lastTouchUtm.medium"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.medium') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.lastTouchUtm.medium }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.lastTouchUtm.term"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.term') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.lastTouchUtm.term }}
              </dd>
            </div>

            <div
              v-if="candidateStore.candidate.metadata.lastTouchUtm.content"
              class="flex flex-col"
            >
              <dt class="text-sm font-medium text-gray-900">
                {{ t('candidates.meta.utm.content') }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ candidateStore.candidate.metadata.lastTouchUtm.content }}
              </dd>
            </div>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { useCandidateStore } from '../../../store/candidateStore'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { ChevronDoubleUpIcon } from '@heroicons/vue/24/outline'

const candidateStore = useCandidateStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const collapsePanel = ref(true)

const notEmpty = (metadataProp) => {
  return Object.keys(metadataProp).findIndex((key) => !!metadataProp[key]) > -1
}
</script>

<template>
  <div class="mt-4 bg-white shadow-sm sm:rounded-lg">
    <div class="flex flex-wrap justify-between gap-8 px-5 py-5">
      <div class="flex flex-col items-start gap-6 sm:flex-row">
        <span
          class="inline-flex h-16 w-16 items-center justify-center rounded-full bg-indigo-400"
        >
          <BellAlertIcon class="w-8 text-white"
        /></span>

        <div class="flex flex-col gap-y-0.5">
          <div class="inline-flex items-center gap-x-1">
            <EnvelopeIcon class="w-6 text-gray-500" />
            <span class="text-lg font-semibold text-gray-900">
              {{ jobAlertStore.jobAlert.email }}
            </span>

            <span
              v-if="jobAlertStore.jobAlert.active"
              class="ml-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
              >{{ 'Active' }}</span
            >
          </div>
          <span class="flex items-center gap-2 text-sm text-gray-900">
            <BuildingOffice2Icon class="w-6 text-gray-500" />
            <span>{{ jobAlertStore.jobAlert.site.name }}</span>
          </span>

          <span class="flex items-center gap-2 text-sm text-gray-900">
            <CalendarDaysIcon class="w-6 text-gray-500" />
            <span>{{
              t(`jobAlerts.frequency.${jobAlertStore.jobAlert.frequency}`)
            }}</span>
          </span>
          <span class="flex items-start gap-2 text-sm text-gray-900">
            <MagnifyingGlassIcon class="w-6 min-w-6 text-gray-500" />
            <div class="flex flex-col flex-wrap" v-html="searchCriteria"></div>
          </span>
        </div>
      </div>

      <div class="ml-2 flex flex-col gap-1 text-sm text-gray-900 lg:ml-auto">
        <div
          class="inline-flex items-center justify-start gap-2 text-xs text-gray-800 lg:justify-end"
        >
          <div class="text-xs text-gray-600">
            {{ toLocalDateTime(new Date(jobAlertStore.jobAlert.createdDate)) }}
          </div>
          <span class="rounded-full bg-gray-100 px-2 leading-5 font-semibold">{{
            timeAgo.format(new Date(jobAlertStore.jobAlert.createdDate))
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useJobAlertStore } from '../../store/jobAlertStore'
import { EnvelopeIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import {
  BellAlertIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
} from '@heroicons/vue/24/outline'
import TimeAgo from 'javascript-time-ago'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'
import { computed } from 'vue'

const jobAlertStore = useJobAlertStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const timeAgo = new TimeAgo()

const formatTitle = (name, value) => {
  if (typeof value === 'string') {
    return `<div><span class="font-semibold">${name}</span>: ${value}</div>`
  } else
    return `<div><span class="font-semibold">${name}</span>: ${value.join(', ')}</div>`
}

const searchCriteria = computed(() => {
  let res = ''
  let criteria
  try {
    criteria = JSON.parse(jobAlertStore.jobAlert?.searchString)
  } catch (error) {
    criteria = {}
  }
  if (criteria?.keyword) {
    res += formatTitle('Keywords', criteria.keyword)
  }
  if (criteria?.duration) {
    res += formatTitle('Duration', criteria.duration)
  }
  if (criteria?.location) {
    res += formatTitle('Location', criteria.location)
  }
  if (criteria?.employmentType?.length) {
    res += formatTitle('Employment type', criteria.employmentType)
  }
  if (criteria?.discipline?.length) {
    res += formatTitle('Discipline', criteria.discipline)
  }
  if (criteria?.education?.length) {
    res += formatTitle('Education', criteria.education)
  }
  if (criteria?.region?.length) {
    res += formatTitle('Region', criteria.region)
  }
  if (!res) {
    res += '-'
  }
  return res
})

const toLocalDateTime = (applicationDate) => {
  return applicationDate ? format(applicationDate, 'dd MMM yyyy, HH:mm') : ''
}
</script>

<template>
  <div class="suggestion-items">
    <template v-if="items.length">
      <button
        v-for="(item, index) in items"
        :key="index"
        class="item"
        :class="[
          { 'is-selected': index === selectedIndex },
          'suggestion-' + index,
        ]"
        @mouseover="hoverHandler(index)"
        @click="selectItem(index)"
      >
        {{ item }}
      </button>
    </template>
    <div v-else class="item">No result</div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  command: {
    type: Function,
    required: true,
  },
})

const selectedIndex = ref(0)

const onKeyDown = ({ event }) => {
  if (event.key === 'ArrowUp') {
    upHandler()
    return true
  }
  if (event.key === 'ArrowDown') {
    downHandler()
    return true
  }
  if (event.key === 'Enter') {
    enterHandler()
    return true
  }
  return false
}
const hoverHandler = (index) => {
  selectedIndex.value = index % props.items.length
}
const upHandler = () => {
  selectedIndex.value =
    (selectedIndex.value + props.items.length - 1) % props.items.length
  scrollToElement()
}
const downHandler = () => {
  selectedIndex.value = (selectedIndex.value + 1) % props.items.length
  scrollToElement()
}
const scrollToElement = () => {
  const element = document.querySelector(
    `.suggestion-items .suggestion-${selectedIndex.value}`,
  )
  if (element) {
    element.scrollIntoView(false)
  }
}
const enterHandler = () => {
  selectItem(selectedIndex.value)
}
const selectItem = (index) => {
  const item = props.items[index]
  if (item) {
    props.command({ id: item })
  }
}
watch(props.items, () => {
  selectedIndex.value = 0
})

defineExpose({
  onKeyDown,
  selectedIndex,
})
</script>

<style>
@reference '../index.css';

.mention {
  @apply inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 text-sm font-medium text-indigo-800 hover:bg-indigo-200;
}
.suggestion-items {
  @apply relative max-h-72 overflow-y-scroll rounded-lg border bg-white p-1 text-sm text-gray-800 shadow-sm;
}
.item {
  @apply m-0 block w-full rounded-lg border border-transparent bg-transparent px-2 py-1 text-left;
}
.item.is-selected {
  @apply rounded-none bg-indigo-600 text-white;
}
</style>

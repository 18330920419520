<template>
  <div id="locationMap" class="bg-white shadow-sm sm:rounded-b-md"></div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed, watch, onMounted } from 'vue'
import { locationIQAPIKey } from '../utils/location'

const props = defineProps({
  location: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const locationData = computed(() => props.location)

let maplibregl = null
let map = null
let marker = null
const displayMarker = () => {
  const geo = locationData.value.geo
  if (geo.lng && geo.lat) {
    map.flyTo({
      center: [geo.lng, geo.lat],
      speed: 2,
      offset: [0, 60],
      zoom: 10,
    })
    if (marker) {
      marker.remove()
    }

    marker = new maplibregl.Marker()
      .setLngLat([geo.lng, geo.lat])
      .setPopup(
        new maplibregl.Popup({
          className: 'sm:rounded-md',
          closeButton: false,
        }).setHTML(
          `<div class="m-2 font-sans text-base"><span class="font-bold">${locationData.value.street} ${locationData.value.houseNumber}</span><br>${locationData.value.zipCode} ${locationData.value.city}</div>`,
        ),
      ) // add popup
      .addTo(map)
      .togglePopup()
  }
}

onMounted(async () => {
  // We load maplibre async so it's not included in the main bundle (it's a big library only used in one component)
  import('maplibre-gl/dist/maplibre-gl.css')
  maplibregl = await import('maplibre-gl')

  map = new maplibregl.Map({
    container: 'locationMap',
    style: `https://tiles.locationiq.com/v3/streets/vector.json?key=${locationIQAPIKey}`,
    center: [5, 52],
    zoom: 10,
    attributionControl: false,
    maxPitch: 0,
    dragRotate: false,
  })

  displayMarker()
})

watch(
  () => locationData.value,
  () => {
    displayMarker()
  },
)
</script>
<style>
@reference '../index.css';

#locationMap {
  height: 300px;
}

.maplibregl-popup-content {
  @apply sm:rounded-md;
}
</style>

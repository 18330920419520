<template>
  <div class="block text-sm font-medium text-gray-700">
    {{ t('site.colorScheme') }}
  </div>
  <div class="relative mt-1 flex space-x-2">
    <ColorInput
      v-for="(color, index) in colors"
      :key="index"
      class="h-8 w-8 rounded-sm"
      :modelValue="color"
      @update:model-value="(color) => updateScheme(color, index)"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import ColorInput from '../ColorInput.vue'

const emit = defineEmits(['updateScheme'])
const props = defineProps({
  scheme: {
    type: String,
    required: true,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const defaultColors = ['#ffffff', '#ffffff']

const colorsFromScheme = (scheme) => {
  if (scheme) {
    return scheme.split(':').map((color) => `#${color}`)
  }
  return defaultColors
}

const colors = computed(() => {
  return colorsFromScheme(props.scheme)
})

const replaceColorInScheme = (color, index) => {
  const scheme = props.scheme || 'ffffff:ffffff'
  const colors = scheme.split(':')
  colors[index] = color.replace('#', '')
  return colors.join(':')
}

const updateScheme = (color, index) => {
  emit('updateScheme', replaceColorInScheme(color, index))
}
</script>

<template>
  <TwoColumnForm :title="t('vacancy.title')">
    <TwoColumnFormCard>
      <div class="mb-4">
        <label class="mb-1 block text-sm font-medium text-gray-700">
          {{ t('archived') }}
        </label>
        <Toggle
          :srLabel="t('archived')"
          :checked="vacancyStore.vacancy.archived"
          :onToggle="onToggle"
        />
      </div>
      <div class="grid grid-cols-8 gap-4">
        <Company class="col-span-8 mb-4 lg:col-span-4 lg:mb-8" />
        <TopJob class="col-span-8 mb-4 lg:col-span-2 lg:mt-8 lg:mb-0" />
      </div>
      <Meta /> </TwoColumnFormCard
  ></TwoColumnForm>
  <TwoColumnFormDivider />
  <TwoColumnForm :title="t('vacancy.description')"
    ><Description id="vacancyDescription"
  /></TwoColumnForm>
  <TwoColumnFormDivider />
  <TwoColumnForm :title="t('vacancy.vacancyImages')">
    <TwoColumnFormCard
      ><FormCarousel
        :label="t('vacancy.vacancyImages')"
        :modelValue="vacancyImages"
        @update:model-value="updateVacancyImages" /></TwoColumnFormCard
  ></TwoColumnForm>
  <TwoColumnFormDivider />
  <TwoColumnForm
    :title="t('vacancy.videos')"
    :helpPopoverText="t('vacancy.videoPopover')"
  >
    <TwoColumnFormCard>
      <FormVideos
        :videoUrls="vacancyStore.vacancy?.videoUrls || []"
        @update-videos="updateVideos"
      />
    </TwoColumnFormCard>
  </TwoColumnForm>
  <TwoColumnFormDivider />
  <TwoColumnForm :title="t('vacancy.contact.title')"
    ><TwoColumnFormCard><Contact /></TwoColumnFormCard
  ></TwoColumnForm>
  <TwoColumnFormDivider />
  <TwoColumnForm :title="t('details')"
    ><TwoColumnFormCard>
      <Employment class="mb-8" />
      <Salary /> </TwoColumnFormCard
  ></TwoColumnForm>
  <TwoColumnFormDivider />
  <TwoColumnForm :title="t('vacancy.location.title')"
    ><Location storeName="vacancy"
  /></TwoColumnForm>
  <TwoColumnFormDivider />
  <TwoColumnForm title="">
    <FormSave
      :validationErrors="v$.$errors"
      :validationTouch="v$.$touch"
      storeAction="saveVacancy"
      storeName="vacancy"
      nextTab="publications"
    />
  </TwoColumnForm>
</template>

<script setup>
import FormSave from '../FormSave.vue'
import Location from '../Location.vue'
import Employment from './Employment.vue'
import Salary from './Salary.vue'
import Meta from './Meta.vue'
import Company from './Company.vue'
import Contact from './ContactPerson.vue'
import TwoColumnForm from '../TwoColumnForm.vue'
import TwoColumnFormCard from '../TwoColumnFormCard.vue'
import TwoColumnFormDivider from '../TwoColumnFormDivider.vue'
import TopJob from './TopJob.vue'
import Toggle from '../Toggle.vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { computed, defineAsyncComponent } from 'vue'
import FormCarousel from '../FormCarousel.vue'
import { useVacancyStore } from '../../store/vacancyStore'
import FormVideos from '../FormVideos.vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const v$ = useVuelidate()
const Description = defineAsyncComponent(() => import('./Description.vue'))
const vacancyStore = useVacancyStore()
const onToggle = () => {
  vacancyStore.vacancy.archived = !vacancyStore.vacancy.archived
}
const vacancyImages = computed(() => vacancyStore.vacancy.imageUrls)

const updateVacancyImages = (images) => {
  vacancyStore.vacancy.imageUrls = images
}

const updateVideos = (videos) => {
  vacancyStore.vacancy.videoUrls = videos
}
</script>

<template>
  <editor-content :editor="editor" />
</template>

<script setup>
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { createLowlight } from 'lowlight'
import css from 'highlight.js/lib/languages/css'
import js from 'highlight.js/lib/languages/javascript'
import html from 'highlight.js/lib/languages/xml'
import 'highlight.js/styles/github.css'
import { watch } from 'vue'

// create a lowlight instance
const lowlight = createLowlight()
lowlight.register('html', html)
lowlight.register('css', css)
lowlight.register('js', js)

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:modelValue'])

let userUpdate = false
const code = `<pre><code>${props.modelValue
  .replace(/>/g, '&gt')
  .replace(/</g, '&lt')}</code></pre>`

const editor = useEditor({
  extensions: [StarterKit, CodeBlockLowlight.configure({ lowlight })],
  editorProps: {
    attributes: {
      class:
        'min-h-[6rem] px-3 py-2 rounded-md shadow-xs focus:outline-hidden sm:text-sm border border-gray-300 focus:ring-1 focus:border-indigo-500 focus:ring-indigo-500',
    },
  },
  content: code,
  onUpdate: ({ editor }) => {
    userUpdate = true

    let content = editor.getText()
    if (!content || content === '<p></p>') {
      content = ''
      editor.commands.setContent(`<pre><code></code></pre>`)
    }

    emit(
      'update:modelValue',
      // Remove <pre><code> tags
      content.replace(/<pre>|<\/pre>|<code>|<\/code>/g, ''),
    )
  },
})

// We need to update the editor once again after the site's data is fetched
const unwatch = watch(
  () => props.modelValue,
  (value) => {
    if (!userUpdate) {
      const code = `<pre><code>${value
        .replace(/>/g, '&gt')
        .replace(/</g, '&lt')}</code></pre>`

      editor.value.commands.setContent(code)
    } else {
      unwatch()
    }
  },
)
</script>

<style>
.ProseMirror pre {
  line-height: 1.25;
}
</style>

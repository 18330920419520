<template>
  <div class="sm:shadow-sm">
    <div class="pb-4 sm:hidden">
      <label for="tabs" class="sr-only">{{ t('selectTab') }}</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select
        id="tabs"
        name="tabs"
        class="block w-full rounded-md border-gray-300 pt-2 pr-10 pl-3 text-base focus:border-indigo-500 focus:ring-indigo-500 focus:outline-hidden sm:text-sm"
        @change="setCurrentTab"
      >
        <option
          v-for="tab in tabs"
          :key="tab.value"
          :selected="tab.value === activeTab"
          :value="tab.value"
        >
          {{ tab.label }}
        </option>
      </select>
    </div>
    <div class="hidden bg-white pt-2 sm:block sm:rounded-t-md">
      <div class="border-b border-gray-200 px-6">
        <nav class="-mb-px flex items-center space-x-8" aria-label="Tabs">
          <a
            v-for="tab in tabs"
            :key="tab.value"
            href="#"
            :class="[
              tab.value === activeTab
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'border-b-2 px-1 py-2 text-sm font-medium whitespace-nowrap',
            ]"
            :aria-current="tab.value === activeTab ? 'page' : undefined"
            @click="setCurrentTab(tab.value)"
          >
            {{ tab.label }}
          </a>
        </nav>
      </div>
    </div>

    <div class="shadow-sm sm:shadow-none">
      <LocationSearchAddress
        v-if="activeTab === 'search'"
        :storeName="storeName"
      />
      <LocationSearchPostCode
        v-if="activeTab === 'postalCode'"
        :storeName="storeName"
      />
      <LocationManual v-if="activeTab === 'manual'" :storeName="storeName" />

      <div v-if="storeName === 'vacancy'" class="-mt-2 bg-white px-6 pb-4">
        <a
          v-if="companyAddress && !isUsingCompanyAddress"
          href="#"
          class="text-sm text-indigo-600 no-underline hover:underline focus:underline"
          @click="useCompanyAddress"
        >
          {{ t('vacancy.location.useCompanyAddress', companyAddress) }}
        </a>
      </div>

      <LocationMap :location="store[storeName].location" />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import LocationMap from './LocationMap.vue'
import LocationSearchAddress from './LocationSearchAddress.vue'
import LocationSearchPostCode from './LocationSearchPostCode.vue'
import LocationManual from './LocationManual.vue'
import { computed, ref } from 'vue'
import { useSettingsStore } from '../store/settingsStore'
import { useVacancyStore } from '../store/vacancyStore'
import { useCompanyStore } from '../store/companyStore'
import { addressIsNotEmpty } from '../utils/location'

const props = defineProps({
  storeName: {
    type: String,
    required: true,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
let store
if (props.storeName === 'vacancy') {
  store = useVacancyStore()
} else if (props.storeName === 'company') {
  store = useCompanyStore()
}

const settingsStore = useSettingsStore()

const tabs = [
  { label: t('vacancy.location.search'), value: 'search' },
  { label: t('vacancy.location.postalcode'), value: 'postalCode' },
  { label: t('vacancy.location.manual'), value: 'manual' },
]
const prefferedTab = computed(
  () => settingsStore.preferredLocationSearch || 'search',
)
const activeTab = ref(prefferedTab.value)

const setCurrentTab = (tab) => {
  activeTab.value = tab?.target?.value ? tab.target.value : tab
}

const companyAddress = computed(() => {
  return addressIsNotEmpty(store.company?.location)
    ? store.company.location
    : null
})

const isUsingCompanyAddress = computed(() => {
  return (
    companyAddress.value?.street === store[props.storeName].location.street &&
    companyAddress.value?.houseNumber ===
      store[props.storeName].location.houseNumber &&
    companyAddress.value?.city === store[props.storeName].location.city &&
    companyAddress.value?.zipCode === store[props.storeName].location.zipCode
  )
})

const useCompanyAddress = async () => {
  store.copyLocationFromCompany()
}
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Sort :sortOptions="sortOptions" @sort="onSort" />
  <div
    v-if="hasMultiselect && entities.length > 0"
    class="ml-2.5 rounded-md py-4"
  >
    <div class="flex h-6 items-center gap-x-2">
      <input
        id="selectAll"
        type="checkbox"
        class="ml-1 h-4 w-4 rounded-sm border-gray-300 text-indigo-600 focus:ring-indigo-500"
        :checked="
          indeterminate ||
          (selected.length === entities.length && entities.length > 0)
        "
        :indeterminate="indeterminate"
        @change="
          selected = $event.target.checked ? entities.map((p) => p.id) : []
        "
      />
      <label for="selectAll" class="text-sm text-gray-700">{{
        selected.length === entities.length
          ? `${t('unselect')} ${selected.length} ${t('items')}`
          : t('selectAllOnPage')
      }}</label>
      <Menu
        v-if="selected.length > 0"
        as="div"
        class="relative ml-2 block text-left"
      >
        <div>
          <MenuButton
            class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-xs hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 focus:outline-hidden"
          >
            {{ t('candidates.actions.title') }}
            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition
          enterActiveClass="transition ease-out duration-100"
          enterFromClass="transform opacity-0 scale-95"
          enterToClass="transform opacity-100 scale-100"
          leaveActiveClass="transition ease-in duration-75"
          leaveFromClass="transform opacity-100 scale-100"
          leaveToClass="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-auto min-w-max origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden"
          >
            <div class="py-1">
              <MenuItem
                v-for="action in multiselectActions"
                :key="action.value"
                v-slot="{ active }"
                @click="emitSelected(action.value)"
              >
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                >
                  <div class="flex items-center gap-x-2 text-gray-700">
                    <component :is="action.icon" class="w-5" />
                    <span v-html="action.label"></span>
                    <span>{{ `(${selected.length})` }}</span>
                  </div>
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <SuccessLabel v-if="processedSuccessfully" :label="t('done')" class="" />
      <div v-if="isLoading" class="flex items-center gap-x-2">
        <LoadingSpinner class="ml-3 h-4 w-4 text-indigo-600" />
        <span>{{ t('processing') }}</span>
      </div>
    </div>
  </div>
  <div class="overflow-auto bg-white shadow-sm sm:rounded-md">
    <ul class="divide-y divide-gray-200">
      <li
        v-for="entity in entities"
        :key="entity.id"
        class="flex items-center hover:bg-gray-50"
      >
        <label class="absolute px-4 py-7">
          <input
            v-if="hasMultiselect"
            v-model="selected"
            type="checkbox"
            class="h-4 w-4 rounded-sm border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
            :value="entity.id"
          />
        </label>
        <div class="basis-full" :class="{ 'ml-7': hasMultiselect }">
          <slot :entity="entity"></slot>
        </div>
      </li>
    </ul>
  </div>

  <Pagination
    v-if="page"
    :page="page"
    :updatePageNumber="updatePageNumber"
    :updatePageSize="updatePageSize"
    @page-updated="resetSelected"
  />
</template>

<script setup>
import Pagination from './Pagination.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Sort from './Sort.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import SuccessLabel from './SuccessLabel.vue'

const props = defineProps({
  entities: {
    type: Array,
    required: true,
  },
  page: {
    type: Object,
    default: null,
  },
  updatePageNumber: {
    type: Function,
    default: () => {},
  },
  updatePageSize: {
    type: Function,
    default: () => {},
  },
  hasMultiselect: {
    type: Boolean,
    default: false,
  },
  multiselectActions: {
    type: Array,
    default: () => [],
  },
  sortOptions: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  processedSuccessfully: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['selectedAction', 'sort'])
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const selected = ref([])
const indeterminate = computed(
  () =>
    selected.value.length > 0 && selected.value.length < props.entities.length,
)
const emitSelected = (actionName) => {
  emit('selectedAction', { actionName, selected })
  //reset after emit action
  selected.value = []
}
const resetSelected = () => {
  selected.value = []
}
const onSort = (params) => {
  emit('sort', { value: params.value, order: params.order })
}
</script>

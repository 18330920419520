import { defineStore } from 'pinia'
import tooManagement from '../api/tooManagement'
import { i18n } from '../main'
import { format } from 'date-fns'

const emptySubscription = {
  id: null,
  principalId: null,
  principalType: '',
  plan: null,
  startDate: '',
  endDate: '',
  autoRenew: false,
  accountManagerEmail: '',
  features: [],
}

const availablePlans = [
  { label: 'Test trial', value: 'TEST_TRIAL', defaultCredits: 5 },
  { label: 'Start', value: 'START', defaultCredits: 15 },
  { label: 'Basic', value: 'BASIC', defaultCredits: 25 },
  { label: 'Plus', value: 'PLUS', defaultCredits: 50 },
  { label: 'Premium', value: 'PREMIUM', defaultCredits: 200 },
]

const featureTypes = [{ label: 'Publish on', value: 'publish-on-site' }]

const availableSites = [
  { label: 'Gemeentebanen', value: 10 },
  { label: 'Zorgbanen', value: 11 },
]

export const useSubscriptionStore = defineStore('subscriptionStore', {
  state: () => ({
    isLoading: true,
    isSaving: false,
    errors: [],
    isSavedSuccessfully: false,
    subscriptions: [],
    selectedSubscription: { ...emptySubscription },
    availablePlans: availablePlans,
    featureTypes: featureTypes,
    availableSites: availableSites,
  }),
  getters: {
    snapshots(state) {
      if (!state.selectedSubscription.snapshots) {
        return []
      }

      const consolidatedSnapshots = [...state.selectedSubscription.snapshots]
      state.selectedSubscription.features.forEach((feature) => {
        consolidatedSnapshots.push(...(feature.snapshots || []))
      })
      // The dates come in ISO format so we can compare the strings directly.
      // We want to sort them by date, descending
      consolidatedSnapshots.sort((a, b) => {
        if (a.createdDate === b.createdDate) {
          return a.id > b.id ? -1 : 1
        }
        return a.createdDate > b.createdDate ? -1 : 1
      })
      return consolidatedSnapshots
    },
  },
  actions: {
    async load(principalId, principalType, subscriptionId) {
      if (!principalId) {
        return
      }

      this.subscriptions = []
      this.errors = []
      this.isLoading = true
      try {
        await this.getSubscriptions(principalId, principalType, subscriptionId)
        await Promise.allSettled([
          this.getSubscriptionSnapshots(),
          this.getSubscriptionInvoices(),
          this.getSubscriptionFeaturesUsages(),
          this.getSubscriptionFeaturesSnapshots(),
        ])
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isLoading = false
      }
    },
    async getSubscriptions(principalId, principalType, subscriptionId) {
      const params = {
        principalId,
        principalType,
        sortyBy: 'startDate',
        sortOrder: 'asc',
      }

      const response = await tooManagement.getSubscription(params)
      if (response.data.length) {
        this.subscriptions = response.data

        this.selectedSubscription =
          this.subscriptions[this.subscriptions.length - 1]
        if (this.subscriptions.length > 1) {
          const desiredSubscription = this.subscriptions.find(
            // Here we use == on purpose because they id may come as a string
            (sub) => sub.id == subscriptionId || sub.state === 'ACTIVATED',
          )
          if (desiredSubscription) {
            this.selectedSubscription = desiredSubscription
          }
        }
      }
    },
    async getSubscriptionSnapshots() {
      this.selectedSubscription.snapshots = []

      const params = {
        page: 1,
        size: 999,
        sortBy: 'id',
        sortOrder: 'desc',
      }
      const result = await tooManagement.getSubscriptionSnapshots(
        this.selectedSubscription.id,
        params,
      )
      result?.data?.forEach((snapshot, index, array) => {
        const startDate = format(snapshot.startDate, 'dd/MM/yyyy')
        const endDate = format(snapshot.endDate, 'dd/MM/yyyy')

        if (index + 1 === array.length) {
          // It's the last element
          snapshot.changes = [
            i18n.global.t('company.subscription.snapshot.planSelected', {
              plan: snapshot.plan,
            }),
            `${startDate}-${endDate}`,
            snapshot.autoRenew
              ? i18n.global.t('company.subscription.snapshot.autoRenewEnabled')
              : i18n.global.t(
                  'company.subscription.snapshot.autoRenewDisabled',
                ),
          ]
        } else {
          const previousSnapshot = array[index + 1]
          const changes = []
          if (snapshot.plan !== previousSnapshot.plan) {
            changes.push(
              i18n.global.t('company.subscription.snapshot.planChanged', {
                previous: previousSnapshot.plan,
                current: snapshot.plan,
              }),
            )
          }
          if (snapshot.state !== previousSnapshot.state) {
            changes.push(
              i18n.global.t('company.subscription.snapshot.stateChanged', {
                previous: previousSnapshot.state,
                current: snapshot.state,
              }),
            )
          }
          if (snapshot.startDate !== previousSnapshot.startDate) {
            changes.push(
              i18n.global.t('company.subscription.snapshot.startDateChanged', {
                previous: format(previousSnapshot.startDate, 'dd/MM/yyyy'),
                current: startDate,
              }),
            )
          }
          if (snapshot.endDate !== previousSnapshot.endDate) {
            changes.push(
              i18n.global.t('company.subscription.snapshot.endDateChanged', {
                previous: format(previousSnapshot.endDate, 'dd/MM/yyyy'),
                current: endDate,
              }),
            )
          }
          if (snapshot.autoRenew !== previousSnapshot.autoRenew) {
            changes.push(
              snapshot.autoRenew
                ? i18n.global.t(
                    'company.subscription.snapshot.autoRenewEnabled',
                  )
                : i18n.global.t(
                    'company.subscription.snapshot.autoRenewDisabled',
                  ),
            )
          }
          snapshot.changes = changes
        }
        snapshot.description = i18n.global.t(`company.subscription.descriptor`)
        snapshot.forSubscription = true
        this.selectedSubscription.snapshots.push(snapshot)
      })
    },
    async getSubscriptionFeaturesSnapshots() {
      const promises = this.selectedSubscription.features.map(
        async (feature, index) => {
          const params = {
            page: 1,
            size: 999,
            sortBy: 'id',
            sortOrder: 'desc',
          }

          const result = await tooManagement.getSubscriptionFeatureSnapshots(
            feature.id,
            params,
          )

          result?.data.forEach((snapshot, index, array) => {
            snapshot.description = `${i18n.global.t(`subscriptions.${feature.slug}`)} ${feature.resources
              .map((resource) => resource.name)
              .join(', ')}`
            snapshot.context = i18n.global.t(
              'company.subscription.snapshot.totalCredits',
              {
                credits: snapshot.planCredits,
              },
            )

            if (index + 1 === array.length) {
              // It's the last element
              snapshot.changes = [
                i18n.global.t('company.subscription.snapshot.initialCredits', {
                  credits: snapshot.planCredits,
                }),
                snapshot.payAsYouGo
                  ? i18n.global.t(
                      'company.subscription.snapshot.payAsYouGoEnabled',
                    )
                  : i18n.global.t(
                      'company.subscription.snapshot.payAsYouGoDisabled',
                    ),
                snapshot.reversible
                  ? i18n.global.t(
                      'company.subscription.snapshot.reversibleEnabled',
                    )
                  : i18n.global.t(
                      'company.subscription.snapshot.reversibleDisabled',
                    ),
              ]
            } else {
              const previousSnapshot = array[index + 1]
              const changes = []
              if (snapshot.planCredits !== previousSnapshot.planCredits) {
                changes.push(
                  i18n.global.t(
                    'company.subscription.snapshot.creditsChanged',
                    {
                      previous: previousSnapshot.planCredits,
                      current: snapshot.planCredits,
                    },
                  ),
                )
              }
              if (snapshot.payAsYouGo !== previousSnapshot.payAsYouGo) {
                changes.push(
                  snapshot.payAsYouGo
                    ? i18n.global.t(
                        'company.subscription.snapshot.payAsYouGoEnabled',
                      )
                    : i18n.global.t(
                        'company.subscription.snapshot.payAsYouGoDisabled',
                      ),
                )
              }
              if (snapshot.reversible !== previousSnapshot.reversible) {
                changes.push(
                  snapshot.reversible
                    ? i18n.global.t(
                        'company.subscription.snapshot.reversibleEnabled',
                      )
                    : i18n.global.t(
                        'company.subscription.snapshot.reversibleDisabled',
                      ),
                )
              }
              if (snapshot.disabled !== previousSnapshot.disabled) {
                changes.push(
                  snapshot.disabled
                    ? i18n.global.t('company.subscription.snapshot.disabled')
                    : i18n.global.t('company.subscription.snapshot.enabled'),
                )
              }
              snapshot.changes = changes
            }
          })

          this.selectedSubscription.features[index].snapshots = result?.data
          this.selectedSubscription.features[index].snapshotsPage =
            result?.meta?.page
        },
      )
      await Promise.allSettled(promises)
    },
    async getSubscriptionFeaturesUsages() {
      const promises = this.selectedSubscription.features.map(
        async (feature, index) => {
          const params = {
            featureId: feature.id,
            page:
              this.selectedSubscription.features[index]?.usagePage?.number || 1,
            size:
              this.selectedSubscription.features[index]?.usagePage?.size || 10,
            sortBy: 'createdDate',
            sortOrder: 'desc',
          }

          const result =
            await tooManagement.getSubscriptionFeatureUsages(params)

          this.selectedSubscription.features[index].usages = result?.data
          this.selectedSubscription.features[index].usagePage =
            result?.meta?.page
        },
      )
      await Promise.allSettled(promises)
    },
    async initializeSubscriptionFeaturesUsages(feature) {
      this.errors = []
      this.isSaving = true
      try {
        await tooManagement.initializeSubscriptionFeature(feature.id)
        await this.getSubscriptionFeaturesUsages()
        // We update the credits
        const { data: updatedFeatureData } =
          await tooManagement.getSubscriptionFeature(feature.id)
        feature.planCredits = updatedFeatureData.planCredits
        feature.usedCredits = updatedFeatureData.usedCredits
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isSaving = false
      }
    },
    async changeSubscriptionState(state) {
      this.errors = []
      this.isSaving = true
      this.isSavedSuccessfully = false
      try {
        const result = await tooManagement.putSubscriptionState(
          this.selectedSubscription.id,
          { state: state },
        )
        this.selectedSubscription.state = result?.data?.state
        this.getSubscriptionSnapshots()
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isSaving = false
      }
    },
    async revertSubscriptionFeatureUsage(featureUsage) {
      this.errors = []
      this.isSaving = true
      this.isSavedSuccessfully = false
      try {
        const { data: reverseData } =
          await tooManagement.revertSubscriptionFeatureUsage(featureUsage.id)
        featureUsage.reversed = reverseData.reversed
        featureUsage.lastModifiedDate = reverseData.lastModifiedDate
        featureUsage.lastModifiedBy = reverseData.lastModifiedBy
        // We update the credits
        const { data: updatedFeatureData } =
          await tooManagement.getSubscriptionFeature(featureUsage.featureId)
        const feature = this.selectedSubscription.features.find(
          (item) => item.id === featureUsage.featureId,
        )
        feature.planCredits = updatedFeatureData.planCredits
        feature.usedCredits = updatedFeatureData.usedCredits
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isSaving = false
      }
    },
    async saveSubscription({ principalId, principalType }) {
      this.errors = []
      this.isSaving = true
      this.isSavedSuccessfully = false
      try {
        let result
        if (this.selectedSubscription.id) {
          result = await tooManagement.putSubscription({
            ...this.selectedSubscription,
            state: undefined, // The state of the subscription is changed using a different endpoint
            features: undefined,
            snapshots: undefined,
          })
        } else {
          const subscription = { ...this.selectedSubscription }
          subscription.principalId = principalId
          subscription.principalType = principalType
          delete subscription.id
          result = await tooManagement.postSubscription(subscription)
        }

        const index = this.subscriptions.findIndex(
          (sub) => sub.id === this.selectedSubscription.id,
        )

        //make copy of the features, so the changes are not overwritten
        const featuresCopy = [...this.selectedSubscription.features]
        this.selectedSubscription = result.data
        this.selectedSubscription.features = featuresCopy
        await this.saveSubscriptionFeatures()

        if (index >= 0) {
          this.subscriptions[index] = this.selectedSubscription
        } else {
          this.subscriptions.push(this.selectedSubscription)
        }

        this.isSavedSuccessfully = true
        this.getSubscriptionSnapshots()
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isSaving = false
      }
    },
    async saveSubscriptionFeatures() {
      this.errors = []
      this.isSaving = true
      this.isSavedSuccessfully = false
      try {
        const featurePromises = this.selectedSubscription.features.map(
          (feature, featureIndex) => {
            const featureCopy = { ...feature }
            featureCopy.subscriptionId = this.selectedSubscription.id
            if (feature.id === 'new') {
              delete featureCopy.id
              return tooManagement
                .postSubscriptionFeature(featureCopy)
                .then((result) => {
                  this.selectedSubscription.features[featureIndex] = {
                    ...result.data,
                    usages: featureCopy.usages,
                    usagePage: featureCopy.usagePage,
                  }
                })
            } else {
              return tooManagement
                .putSubscriptionFeature(featureCopy)
                .then((result) => {
                  this.selectedSubscription.features[featureIndex] = {
                    ...result.data,
                    usages: featureCopy.usages,
                    usagePage: featureCopy.usagePage,
                  }
                })
            }
          },
        )
        await Promise.allSettled(featurePromises)
        await this.getSubscriptionFeaturesSnapshots()
      } catch (error) {
        this.errors.push(error)
      }
    },
    addSubscription(accountManager) {
      const lastSubscription = this.subscriptions[this.subscriptions.length - 1]
      // You can only have one unsaved subscription at a time
      if (lastSubscription.id) {
        const emptyConfig = JSON.parse(JSON.stringify(emptySubscription))
        emptyConfig.accountManagerEmail = accountManager
        this.selectedSubscription = emptyConfig
        this.subscriptions.push(emptyConfig)
        this.errors = []
        this.isSavedSuccessfully = false
      }
    },
    changeSelectedSubscription(index) {
      this.selectedSubscription = this.subscriptions[index]
      this.errors = []
      this.isSavedSuccessfully = false

      this.getSubscriptionSnapshots()
      this.getSubscriptionInvoices()
      this.getSubscriptionFeaturesUsages()
      this.getSubscriptionFeaturesSnapshots()
    },
    async getSubscriptionInvoices() {
      this.selectedSubscription.invoices = []

      const params = {
        subscriptionId: this.selectedSubscription.id,
        page: this.selectedSubscription.invoicesPage?.page || 1,
        size: this.selectedSubscription.invoicesPage?.size || 10,
        sortBy: 'createdDate',
        sortOrder: 'desc',
      }
      const result = await tooManagement.getSubscriptionInvoices(params)
      this.selectedSubscription.invoices = result.data
      this.selectedSubscription.invoicesPage = result.meta.page
    },
    async addInvoice({ invoicePeriod, features }) {
      this.errors = []
      this.isSaving = true
      this.isSavedSuccessfully = false
      try {
        await tooManagement.postSubscriptionInvoice({
          subscriptionId: this.selectedSubscription.id,
          startDate: invoicePeriod[0],
          endDate: invoicePeriod[1],
          features: features.map((feature) => feature.id),
        })
        this.isSavedSuccessfully = true
        this.getSubscriptionInvoices()
        this.getSubscriptionFeaturesUsages()
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isSaving = false
      }
    },
  },
})

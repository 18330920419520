<template>
  <div
    class="mt-4 mb-4 grid grid-cols-9 items-center gap-x-4 gap-y-2 rounded-md"
  >
    <div v-if="authStore.isCampaignManager" class="col-span-9 flex gap-x-4">
      <div class="grow">
        <label for="search" class="sr-only text-sm font-medium text-gray-700">{{
          t('search')
        }}</label>
        <div class="flex rounded-md shadow-xs">
          <div class="relative grow self-center focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              ref="searchInput"
              v-debounce:500ms="filterCandidates"
              type="search"
              :value="candidateListStore.filter"
              name="search"
              class="block w-full rounded-md border-gray-300 pl-10 text-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Search candidates"
            />
            <div
              v-if="!candidateListStore.filter"
              class="absolute inset-y-0 right-0 hidden py-1.5 pr-1.5 sm:block"
            >
              <kbd
                class="inline-flex items-center rounded-sm border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400"
              >
                Ctrl /
              </kbd>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-9 ml-1 lg:col-span-3">
      <Listbox
        v-model="flowType"
        as="div"
        class="flex flex-col gap-x-2 lg:flex-row lg:items-center"
        @update:model-value="updateFilters(true)"
      >
        <ListboxLabel class="block shrink-0 text-sm font-medium text-gray-700">
          {{ t('candidates.filters.flowType') }}
        </ListboxLabel>
        <div class="relative md:w-full">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1.5 pr-10 pl-3 text-left shadow-xs focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-hidden sm:text-sm"
          >
            <span class="ml-2 block truncate">{{
              flowType
                ? t(`candidates.flowType.${flowType}`)
                : t('candidates.filters.all')
            }}</span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leaveActiveClass="transition ease-in duration-100"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 shadow-lg ring-black/5 focus:outline-hidden sm:text-sm"
            >
              <ListboxOption
                v-for="(type, index) in flowTypes"
                :key="index"
                v-slot="{ active, selected }"
                as="template"
                :value="type"
              >
                <li
                  :class="[
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    'relative cursor-default py-2 pr-9 pl-3 select-none',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                  >
                    {{
                      type
                        ? t(`candidates.flowType.${type}`)
                        : t('candidates.filters.all')
                    }}
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div class="col-span-9 lg:col-span-2">
      <Listbox
        v-model="executionState"
        as="div"
        class="flex w-full flex-col gap-x-2 lg:flex-row lg:items-center"
        @update:model-value="updateFilters(true)"
      >
        <ListboxLabel class="block shrink-0 text-sm font-medium text-gray-700">
          {{ t('candidates.filters.status') }}
        </ListboxLabel>
        <div class="relative md:w-full">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1.5 pr-10 pl-3 text-left shadow-xs focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-hidden sm:text-sm"
          >
            <span class="flex h-6 items-center">
              <component
                :is="executionState.icon"
                class="w-6 fill-white"
                :class="executionState.color"
              />
              <span class="ml-2 block truncate">{{
                executionState.label || t('candidates.filters.all')
              }}</span>
            </span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leaveActiveClass="transition ease-in duration-100"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 shadow-lg ring-black/5 focus:outline-hidden sm:text-sm"
            >
              <ListboxOption
                v-for="(status, index) in possibleStates"
                :key="index"
                v-slot="{ active, selected }"
                as="template"
                :value="status"
              >
                <li
                  :class="[
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    'relative cursor-default py-2 pr-9 pl-3 select-none',
                  ]"
                >
                  <div class="flex items-center">
                    <component
                      :is="status.icon"
                      class="w-6 fill-white"
                      :class="status.color"
                    />
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        index > 0 ? 'ml-2 block truncate' : 'block truncate',
                      ]"
                    >
                      {{
                        index > 0 ? status.label : t('candidates.filters.all')
                      }}
                    </span>
                  </div>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div
      class="col-span-9 flex flex-col items-start gap-x-2 lg:col-span-3 lg:flex-row lg:items-center"
    >
      <label
        for="dateRange"
        class="block shrink-0 text-sm font-medium text-gray-700"
        >{{ t('candidates.dateRange') }}</label
      >

      <DatePicker
        :inputAttr="{ id: 'dateRange' }"
        range
        :shortcuts="shortcuts"
        :value="candidateListStore.dateRangeFilter"
        type="dateTime"
        format="D MMM YYYY"
        inputClass="focus:border-indigo-500 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 w-full block rounded-md shadow-xs focus:outline-hidden sm:text-sm"
        :disabledDate="disabledEndDate"
        :clearable="true"
        :editable="false"
        class="mt-1"
        @change="updateDateRange"
      />
    </div>
    <div class="col-span-1 mt-1 ml-auto">
      <button
        type="button"
        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-xs hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden sm:w-auto"
        @click="updateFilters(false)"
      >
        {{ t('candidates.refresh') }}
        <LoadingSpinner
          v-if="candidateListStore.isLoading"
          class="ml-3 h-4 w-4"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { possibleStates } from '../../../utils/flowActionStates.js'
import { useCandidateListStore } from '../../../store/candidateListStore'
import { useAuthStore } from '../../../store/authStore'
import vueDebounce from 'vue-debounce'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import LoadingSpinner from '../../LoadingSpinner.vue'
import { ChevronUpDownIcon } from '@heroicons/vue/24/outline'
import {
  isToday,
  isAfter,
  startOfDay,
  startOfWeek,
  endOfDay,
  endOfWeek,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfMonth,
  setDefaultOptions,
} from 'date-fns'

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import router from '../../../router'

const vDebounce = vueDebounce({
  lock: true,
  fireOnEmpty: true,
  listenTo: 'input',
})

onBeforeMount(() => {
  setDefaultOptions({ weekStartsOn: 1 })
  if (route.query.flowType) {
    candidateListStore.flowTypeFilter = route.query.flowType
  }
  if (route.query.executionState) {
    candidateListStore.executionStateFilter = possibleStates.find(
      (state) => state.label === route.query.executionState,
    )
  }
  if (route.query.startDate && route.query.endDate) {
    candidateListStore.dateRangeFilter[0] = new Date(route.query.startDate)
    candidateListStore.dateRangeFilter[1] = new Date(route.query.endDate)
  }
  updateFilters(false)
})

const candidateListStore = useCandidateListStore()
const authStore = useAuthStore()
const route = useRoute()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const flowTypes = [
  '',
  'vacancy-apply',
  'vacancy-orientation-day-apply',
  'vacancy-newsletter-signup',
  'company-jobalert',
  'contact-form',
  'share-by-email',
]

const flowType = computed({
  get() {
    return candidateListStore.flowTypeFilter
  },
  set(value) {
    candidateListStore.flowTypeFilter = value
  },
})
const executionState = computed({
  get() {
    return candidateListStore.executionStateFilter
  },
  set(value) {
    candidateListStore.executionStateFilter = value
  },
})
const updatePageNumber = (number) => {
  candidateListStore.page.number = number
  candidateListStore.loadCandidates(
    route.params.id,
    candidateListStore.flowTypeFilter,
    candidateListStore.executionStateFilter.label,
    candidateListStore.dateRangeFilter,
  )
}
const filterCandidates = (value) => {
  candidateListStore.page.number = 1
  candidateListStore.filter = value
  candidateListStore.loadCandidates(
    route.params.id,
    candidateListStore.flowTypeFilter,
    candidateListStore.executionStateFilter.label,
    candidateListStore.dateRangeFilter,
  )
}

const updateFilters = (changeUrl) => {
  updatePageNumber(1)
  candidateListStore.loadCandidatesStatistics(
    route.params.id,
    candidateListStore.executionStateFilter.label,
    candidateListStore.dateRangeFilter,
  )

  if (changeUrl) {
    router.push({
      query: {
        ...route.params.query,
        flowType: candidateListStore.flowTypeFilter,
        executionState: candidateListStore.executionStateFilter.label,
        startDate: candidateListStore.dateRangeFilter[0]?.toISOString() || '',
        endDate: candidateListStore.dateRangeFilter[1]?.toISOString() || '',
      },
    })
  }
}

const shortcuts = [
  {
    text: t('candidates.dateFilter.today'),
    onClick() {
      const today = new Date()
      return [startOfDay(today), today]
    },
  },
  {
    text: t('candidates.dateFilter.yesterday'),
    onClick() {
      const yesterday = subDays(new Date(), 1)
      return [startOfDay(yesterday), endOfDay(yesterday)]
    },
  },
  {
    text: t('candidates.dateFilter.thisWeek'),
    onClick() {
      const today = new Date()
      const week = startOfWeek(new Date())
      return [week, today]
    },
  },
  {
    text: t('candidates.dateFilter.lastWeek'),
    onClick() {
      const lastWeek = subWeeks(new Date(), 1)
      return [startOfWeek(lastWeek), endOfWeek(lastWeek)]
    },
  },
  {
    text: t('candidates.dateFilter.thisMonth'),
    onClick() {
      const monthStart = startOfMonth(new Date())
      return [monthStart, new Date()]
    },
  },
  {
    text: t('candidates.dateFilter.lastMonth'),
    onClick() {
      const lastMonth = subMonths(new Date(), 1)
      return [startOfMonth(lastMonth), endOfMonth(lastMonth)]
    },
  },
  {
    text: t('candidates.dateFilter.thisYear'),
    onClick() {
      const yearStart = startOfYear(new Date())
      return [yearStart, new Date()]
    },
  },
  {
    text: t('candidates.dateFilter.lastYear'),
    onClick() {
      const lastYear = subYears(new Date(), 1)
      return [startOfYear(lastYear), endOfYear(lastYear)]
    },
  },
]

const updateDateRange = (value) => {
  candidateListStore.page.number = 1
  candidateListStore.dateRangeFilter[0] = value[0]
  candidateListStore.dateRangeFilter[1] = value[1] // We need this for clearing the search
  //if endDate is today go until current time otherwise go until end of the day
  if (value[1]) {
    candidateListStore.dateRangeFilter[1] = isToday(value[1])
      ? new Date()
      : endOfDay(value[1])
  }
  router.push({
    query: {
      ...route.params.query,
      flowType: candidateListStore.flowTypeFilter,
      executionState: candidateListStore.executionStateFilter.label,
      startDate: candidateListStore.dateRangeFilter[0]?.toISOString() || '',
      endDate: candidateListStore.dateRangeFilter[1]?.toISOString() || '',
    },
  })
  candidateListStore.loadCandidates(
    route.params.id,
    candidateListStore.flowTypeFilter,
    candidateListStore.executionStateFilter.label,
    candidateListStore.dateRangeFilter,
  )
  candidateListStore.loadCandidatesStatistics(
    route.params.id,
    candidateListStore.executionStateFilter.label,
    candidateListStore.dateRangeFilter,
  )
}

const disabledEndDate = (date) => isAfter(date, new Date())
const searchInput = ref(null)

const onKeydown = (event) => {
  if (event.key === '/' && event.ctrlKey) {
    searchInput.value?.focus()
  }
}

window.addEventListener('keydown', onKeydown)

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeydown)
})
</script>

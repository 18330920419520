<template>
  <div class="grid grid-cols-6 items-start gap-6">
    <div class="col-span-6 lg:col-span-3">
      <FormInput
        id="newTagGroupName"
        type="text"
        :value="tagGroupName"
        :label="t('site.tags.groupNameLabel')"
        :onInput="updateTagGroupName"
        :rules="nameRequired"
      />
    </div>
    <div class="col-span-6 lg:col-span-3">
      <Listbox v-model="tagGroupCategory" as="div">
        <ListboxLabel class="block text-sm font-medium text-gray-700">{{
          t('site.tags.groupCategoryLabel')
        }}</ListboxLabel>
        <div class="relative mt-1">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pr-10 pl-3 text-left shadow-xs focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-hidden sm:text-sm"
          >
            <span class="block truncate">{{ tagGroupCategory.name }}</span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leaveActiveClass="transition ease-in duration-100"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base ring-1 shadow-lg ring-black/5 focus:outline-hidden sm:text-sm"
            >
              <ListboxOption
                v-for="category in siteStore.tagGroupsCategories"
                :key="category.value"
                v-slot="{ active, selected }"
                as="template"
                :value="category"
              >
                <li
                  :class="[
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    'relative cursor-default py-2 pr-9 pl-3 select-none',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                    >{{ category.name }}</span
                  >

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>

    <div class="col-span-6 flex flex-row-reverse items-center">
      <button
        type="button"
        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-xs hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
        :disabled="siteStore.isLoading"
        @click="addTagGroup"
      >
        {{ t('site.tags.newGroup') }}
        <LoadingSpinner
          v-if="siteStore.isLoading || siteStore.isSaving"
          class="ml-3 h-4 w-4 text-white"
        />
      </button>
      <SuccessLabel
        v-if="!siteStore.isLoading && siteStore.isSavedSuccessfully"
        :label="t('site.saved')"
        class="mx-4"
      />
    </div>
    <div v-if="siteStore.tagErrors.length" class="col-span-6">
      <ErrorMessages :errors="siteStore.tagErrors" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'

import ErrorMessages from '../ErrorMessages.vue'
import LoadingSpinner from '../LoadingSpinner.vue'
import FormInput from '../FormInput.vue'
import SuccessLabel from '../SuccessLabel.vue'
import { useSiteStore } from '../../store/siteStore'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const siteStore = useSiteStore()

const nameRequired = {
  required: helpers.withMessage(t('validations.emptyField'), required),
}
const tagGroupName = ref('')
const tagGroupCategory = ref(siteStore.tagGroupsCategories[0])

const rules = {
  tagGroupName: nameRequired,
}
const v$ = useVuelidate(rules, { tagGroupName }, { $stopPropagation: true })

const updateTagGroupName = (newName) => {
  tagGroupName.value = newName
}

const addTagGroup = async () => {
  v$.value.$touch()
  if (!v$.value.$error) {
    await siteStore.addTagGroup({
      name: tagGroupName.value,
      tagCategory: tagGroupCategory.value.value,
    })

    v$.value.$reset()
    tagGroupName.value = ''
    tagGroupCategory.value = siteStore.tagGroupsCategories[0]
  }
}
</script>

<template>
  <fieldset v-if="authStore.isAdmin">
    <legend class="sr-only">{{ t('vacancy.topJob') }}</legend>
    <div class="relative flex items-start">
      <div class="flex h-5 items-center">
        <input
          id="isTopJob"
          :checked="vacancyStore.vacancy.isTopItem"
          aria-describedby="is-topjob"
          name="isTopJob"
          type="checkbox"
          class="h-4 w-4 rounded-sm border-gray-300 text-indigo-600 focus:ring-indigo-500"
          @change="onToggle"
        />
      </div>
      <div class="ml-3 text-sm">
        <label for="isTopJob" class="font-medium text-gray-700">{{
          t('vacancy.topJob')
        }}</label>
      </div>
    </div>
  </fieldset>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useVacancyStore } from '../../store/vacancyStore'
import { useAuthStore } from '../../store/authStore'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const vacancyStore = useVacancyStore()
const authStore = useAuthStore()

const onToggle = () => {
  vacancyStore.vacancy.isTopItem = !vacancyStore.vacancy.isTopItem
}
</script>

<template>
  <div class="grid grid-cols-6 items-start gap-6">
    <div class="col-span-6 lg:col-span-3">
      <FormInput
        id="firstName"
        type="text"
        :value="firstName"
        :label="t('publisher.user.firstName')"
        :onInput="updateFirstName"
        :rules="firstNameRules"
      />
    </div>
    <div class="col-span-6 lg:col-span-3">
      <FormInput
        id="lastName"
        type="text"
        :value="lastName"
        :label="t('publisher.user.lastName')"
        :onInput="updateLastName"
        :rules="lastNameRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="userEmail"
        type="email"
        :value="userEmail"
        :label="t('publisher.user.email')"
        :onInput="updateEmail"
        :rules="emailRules"
      />
    </div>
    <div class="col-span-6 flex flex-row-reverse items-center">
      <button
        type="button"
        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-xs hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
        :disabled="publisherStore.isLoading"
        @click="inviteUser"
      >
        {{ t('publisher.user.invite') }}
        <LoadingSpinner
          v-if="publisherStore.isLoading"
          class="ml-3 h-4 w-4 text-white"
        />
      </button>
      <SuccessLabel
        v-if="!publisherStore.isLoading && publisherStore.isSavedSuccessfully"
        label="Invitation sent"
        class="mx-4"
      />
    </div>
    <div v-if="publisherStore.userErrors.length" class="col-span-6">
      <ErrorMessages :errors="publisherStore.userErrors" />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { usePublisherStore } from '../../store/publisherStore'
import { ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import FormInput from '../FormInput.vue'
import LoadingSpinner from '../LoadingSpinner.vue'
import SuccessLabel from '../SuccessLabel.vue'
import ErrorMessages from '../ErrorMessages.vue'

const publisherStore = usePublisherStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const firstName = ref('')
const firstNameRules = {
  required: helpers.withMessage(
    t('validations.userFirstName.required'),
    required,
  ),
}
const updateFirstName = (newName) => {
  firstName.value = newName
}

const lastName = ref('')
const lastNameRules = {
  required: helpers.withMessage(
    t('validations.userLastName.required'),
    required,
  ),
}
const updateLastName = (newName) => {
  lastName.value = newName
}

const userEmail = ref('')
const emailRules = {
  required: helpers.withMessage(t('validations.userEmail.required'), required),
  email: helpers.withMessage(t('validations.userEmail.email'), email),
}
const updateEmail = (newValue) => {
  userEmail.value = newValue
}

const inviteUser = async () => {
  v$.value.$touch()
  if (!v$.value.$error) {
    await publisherStore.inviteUser({
      email: userEmail.value,
      firstName: firstName.value,
      lastName: lastName.value,
    })

    v$.value.$reset()
    firstName.value = ''
    lastName.value = ''
    userEmail.value = ''
  }
}

const rules = {
  firstName: firstNameRules,
  lastName: lastNameRules,
  userEmail: emailRules,
}

const v$ = useVuelidate(
  rules,
  { firstName, lastName, userEmail },
  { $stopPropagation: true },
)
</script>

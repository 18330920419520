<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ListHeader
        :title="t('menu.publishers')"
        :isLoading="publisherListStore.isLoading"
        :enableSearch="true"
        :searchPlaceholder="t('publishers.searchPlaceholder')"
        :searchTerm="publisherListStore.filter"
        @update:search-term="updateFilter"
      >
        <router-link
          to="/publishers/new/general"
          class="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-xs hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
        >
          {{ t('publishers.createNew') }}
        </router-link>
      </ListHeader>
      <EmptyStateCard
        v-if="isEmpty"
        class="mt-5"
        :title="t('menu.publishers')"
        :message="t('publishers.emptyPublishers')"
      >
        <template #icon>
          <UserIcon class="" />
        </template>
      </EmptyStateCard>

      <ErrorMessage
        v-if="publisherListStore.error"
        :error="publisherListStore.error"
      />

      <div
        v-if="!isEmpty"
        class="mb-4 flex flex-wrap gap-6 rounded-md pl-0.5 md:flex-row"
      >
        <div class="flex w-full items-center gap-2 md:w-auto">
          <div class="flex items-center gap-2">
            <input
              id="excludeTestCheck"
              :checked="publisherListStore.includeArchived"
              type="checkbox"
              @change="toggleIncludeArchived"
            />
            <label
              for="excludeTestCheck"
              class="inline-flex items-center gap-1 text-sm font-medium text-gray-700"
            >
              {{ t('include') }}
              <span
                class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                >{{ t('archived') }}</span
              ></label
            >
          </div>
        </div>
      </div>

      <List
        v-if="!isEmpty"
        v-slot="{ entity: publisher }"
        :entities="publisherListStore.publishers"
        :page="publisherListStore.page"
        :updatePageNumber="updatePageNumber"
        :updatePageSize="updatePageSize"
      >
        <router-link
          :to="`/publishers/${publisher.id}/general`"
          class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-hidden"
        >
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p class="truncate text-sm font-medium text-indigo-600">
                {{ publisher.name }}
              </p>
              <div class="ml-2 flex shrink-0">
                <span
                  v-if="publisher.archived"
                  class="mr-2 ml-0 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 xl:ml-auto"
                  >{{ t('archived') }}</span
                >
                <p class="flex items-center text-xs text-gray-500">
                  {{ publisher.code }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </List>
    </div>
  </div>
</template>

<script setup>
import ListHeader from '../components/ListHeader.vue'
import List from '../components/List.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import EmptyStateCard from '../components/EmptyStateCard.vue'
import { UserIcon } from '@heroicons/vue/20/solid'
import { usePublisherListStore } from '../store/publisherListStore'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const publisherListStore = usePublisherListStore()
const isEmpty = computed(
  () =>
    !publisherListStore.isLoading &&
    publisherListStore.filter === '' &&
    publisherListStore.publishers.length === 0,
)

publisherListStore.load()

const updateFilter = (filter) => {
  publisherListStore.filter = filter
  publisherListStore.page.number = 1
  publisherListStore.load()
}
const updatePageNumber = (number) => {
  publisherListStore.page.number = number
  publisherListStore.load()
}
const updatePageSize = (pageSize) => {
  publisherListStore.page.size = pageSize
  publisherListStore.load()
}
const toggleIncludeArchived = () => {
  publisherListStore.includeArchived = !publisherListStore.includeArchived
  publisherListStore.load()
}
</script>

<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ErrorMessage v-if="jobAlertStore.error" :error="jobAlertStore.error" />
      <div v-else>
        <div class="flex items-center justify-end gap-x-2">
          <router-link
            :to="{
              name: 'jobAlerts',
            }"
            class="mr-auto inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500 hover:underline"
          >
            <ArrowLeftIcon class="h-6 w-6" /> {{ t('jobAlerts.goBack') }}
          </router-link>
          <LoadingSpinner v-if="jobAlertStore.isLoading" class="ml-3 h-4 w-4" />
          <Menu
            v-if="authStore.isAdmin"
            as="div"
            class="relative block text-left"
          >
            <div>
              <MenuButton
                class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-xs hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 focus:outline-hidden"
              >
                {{ t('candidates.actions.title') }}
                <ChevronDownIcon
                  class="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </MenuButton>
            </div>

            <transition
              enterActiveClass="transition ease-out duration-100"
              enterFromClass="transform opacity-0 scale-95"
              enterToClass="transform opacity-100 scale-100"
              leaveActiveClass="transition ease-in duration-75"
              leaveFromClass="transform opacity-100 scale-100"
              leaveToClass="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-auto min-w-max origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active }" @click="removeJobAlert">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      >{{ t('jobAlerts.removeAlert') }}
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div v-if="jobAlertStore.jobAlert.id">
          <JobAlertInfo />
          <AlertVacancyList />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'
import { useRoute } from 'vue-router'
import router from '../router.js'
import { useI18n } from 'vue-i18n'
import { onUnmounted } from 'vue'
import LoadingSpinner from '../components/LoadingSpinner.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import { useJobAlertStore } from '../store/jobAlertStore'
import { useJobAlertListStore } from '../store/jobAlertListStore'
import { useAuthStore } from '../store/authStore'
import JobAlertInfo from '../components/jobAlert/JobAlertInfo.vue'
import AlertVacancyList from '../components/jobAlert/AlertVacancyList.vue'

const jobAlertStore = useJobAlertStore()
const jobAlertListStore = useJobAlertListStore()
const authStore = useAuthStore()
const route = useRoute()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

onUnmounted(() => {
  jobAlertStore.$reset()
})

jobAlertStore.load(route.params.id)

const removeJobAlert = () => {
  jobAlertListStore.removeJobAlerts([route.params.id])
  router.replace('/job-alerts')
}
</script>

<template>
  <div class="mt-5 flex flex-col px-2">
    <Multiselect
      id="activePublisher"
      class="text-sm shadow-xs"
      :modelValue="settingsStore.activePublisher"
      :placeholder="t('settings.selectPublisher')"
      :filterResults="false"
      :resolveOnLoad="true"
      :delay="300"
      openDirection="top"
      :searchable="true"
      :options="searchPublishers"
      label="name"
      valueProp="id"
      :object="true"
      inputType="search"
      autocomplete="off"
      mode="single"
      @change="updateActivePublisher"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useSettingsStore } from '../store/settingsStore'
import { useVacancyListStore } from '../store/vacancyListStore'
import { useRouter } from 'vue-router'
import Multiselect from '@vueform/multiselect'
import tooManagement from '../api/tooManagement'

const settingsStore = useSettingsStore()
const vacancyListStore = useVacancyListStore()
const router = useRouter()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const updateActivePublisher = (selectedPublisher) => {
  if (selectedPublisher) {
    settingsStore.activePublisher = selectedPublisher
  } else {
    settingsStore.activePublisher = { name: '', id: null }
  }
  router.push('/vacancies')
  vacancyListStore.load()
}

let firstFetch = true
const searchPublishers = async (filter = '') => {
  if (firstFetch || filter) {
    const queryRes = await tooManagement.getPublishers({
      filter: filter || '',
      size: 100,
    })
    firstFetch = false
    settingsStore.publisherList = queryRes.data
  }

  return settingsStore.publisherList
}
</script>

<template>
  <span class="mx-1 text-gray-200">---></span>
  <button
    v-show="!addFlowAction"
    type="button"
    class="inline-flex items-center rounded-full border border-transparent bg-indigo-500 text-white shadow-xs hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
    @click="toggleAddFlowAction"
  >
    <PlusSmallIcon class="h-5 w-5" aria-hidden="true" />
  </button>

  <div
    v-show="addFlowAction"
    class="relative z-30 inline-flex rounded-md shadow-xs"
  >
    <button
      type="button"
      class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-hidden"
    >
      Select action
    </button>
    <Menu as="span" class="relative -ml-px block">
      <MenuButton
        class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-hidden"
      >
        <span class="sr-only">Open options</span>
        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
      <transition
        enterActiveClass="transition ease-out duration-100"
        enterFromClass="transform opacity-0 scale-95"
        enterToClass="transform opacity-100 scale-100"
        leaveActiveClass="transition ease-in duration-75"
        leaveFromClass="transform opacity-100 scale-100"
        leaveToClass="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 mt-2 -mr-1 w-64 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden"
        >
          <MenuItem
            v-for="action in flowStore.flowActionsTypes[flow.flowTypeSlug]"
            :key="action.value"
            @click="newFlowActionChange(action)"
          >
            <span
              :class="{ 'ml-3': action.level === 2 }"
              class="flex cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
            >
              <span v-show="action.level === 2" class="mr-2">└</span>
              <EnvelopeIcon
                v-if="action.slug === 'send-email' && action.level === 1"
                class="mr-2 -ml-1 w-5"
                aria-hidden="true"
              />
              <svg
                v-if="action.value.startsWith('ats') && action.level === 1"
                class="mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>

              {{ action.title }}</span
            >
          </MenuItem>
          <MenuItem @click="newFlowActionChange">
            <span
              class="flex cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
            >
              {{ 'Cancel' }}</span
            >
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
  <span v-show="!isLast" class="mx-1 text-gray-200">---></span>
</template>

<script setup>
import { PlusSmallIcon } from '@heroicons/vue/24/outline'
import { useFlowStore } from '../../store/flowStore'
import { ref } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  isLast: {
    type: Boolean,
    default: false,
  },
  flow: {
    type: Object,
    required: true,
  },
  index: {
    type: [Number, null],
    default: null,
  },
})

const flowStore = useFlowStore()
let addFlowAction = ref(false)
const toggleAddFlowAction = () => {
  addFlowAction.value = !addFlowAction.value
}

const newFlowActionChange = (action) => {
  if (!action.value) {
    toggleAddFlowAction()
    return
  }
  flowStore.setEmailActionConfig(action.value, props.flow.flowTypeSlug)
  flowStore.addFlowAction({
    active: true,
    config: flowStore.emailActionConfig,
    flowActionTypeSlug: action.slug,
    name: action.title,
    value: action.value,
  })
  toggleAddFlowAction()
}
</script>

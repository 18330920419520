<template>
  <TwoColumnForm :title="t('site.tags.tagGroups')">
    <List
      v-if="!isEmpty"
      v-slot="{ entity: tagGroup }"
      :entities="siteStore.tagGroups"
    >
      <div
        v-if="tagGroup.id === selectedTagGroup?.id"
        class="grid grid-cols-6 items-start gap-6 px-4 py-4 sm:px-6"
      >
        <div class="col-span-6 lg:col-span-3">
          <FormInput
            id="tagGroupName"
            type="text"
            :value="tagGroupName"
            :label="t('site.tags.groupNameLabel')"
            :onInput="updateTagGroupName"
            :rules="nameRequired"
          />
        </div>
        <div class="col-span-6 flex items-center gap-x-4 lg:col-span-3">
          <div class="grow">
            <Listbox v-model="tagGroupCategory" as="div">
              <ListboxLabel class="block text-sm font-medium text-gray-700">{{
                t('site.tags.groupCategoryLabel')
              }}</ListboxLabel>
              <div class="relative mt-1">
                <ListboxButton
                  class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pr-10 pl-3 text-left shadow-xs focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-hidden sm:text-sm"
                >
                  <span class="block truncate">{{
                    tagGroupCategory.name
                  }}</span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronUpDownIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leaveActiveClass="transition ease-in duration-100"
                  leaveFromClass="opacity-100"
                  leaveToClass="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base ring-1 shadow-lg ring-black/5 focus:outline-hidden sm:text-sm"
                  >
                    <ListboxOption
                      v-for="category in siteStore.tagGroupsCategories"
                      :key="category.value"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="category"
                    >
                      <li
                        :class="[
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default py-2 pr-9 pl-3 select-none',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                          >{{ category.name }}</span
                        >

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <button
            class="mt-5 rounded-xs focus:border-red-400 focus:ring-2 focus:ring-red-400 focus:outline-hidden"
            :aria-label="t('publisher.user.deleteTitle')"
            @click.prevent="editTagGroup(tagGroup)"
          >
            <CheckIcon class="h-5 w-5 text-gray-400 hover:text-red-400" />
          </button>
        </div>
      </div>
      <router-link
        v-else
        :to="`/sites/${route.params.id}/tags/${tagGroup.id}`"
        class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-hidden"
      >
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center justify-between">
            <p class="truncate text-sm">
              {{ tagGroup.name }}
            </p>
            <span
              class="ml-auto inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-yellow-700/10 ring-inset"
              >{{
                t(`vacancy.publication.tagGroups.${tagGroup.tagCategory}`)
              }}</span
            >
            <div class="ml-2 flex shrink-0">
              <button
                class="rounded-xs focus:border-red-400 focus:ring-2 focus:ring-red-400 focus:outline-hidden"
                :aria-label="t('publisher.user.deleteTitle')"
                @click.prevent="setActiveEditGroup(tagGroup)"
              >
                <PencilSquareIcon
                  class="h-5 w-5 text-gray-400 hover:text-red-400"
                />
              </button>
            </div>
          </div>
        </div>
      </router-link>
    </List>
    <EmptyStateCard v-else :message="t('site.emptyTagGroup')" />
  </TwoColumnForm>
  <TwoColumnFormDivider />
  <TwoColumnForm :title="t('site.tags.createGroup')">
    <CreateTagGroup />
  </TwoColumnForm>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  PencilSquareIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/vue/20/solid'
import { useRoute } from 'vue-router'
import EmptyStateCard from '../EmptyStateCard.vue'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import List from '../List.vue'
import TwoColumnForm from '../TwoColumnForm.vue'
import TwoColumnFormDivider from '../TwoColumnFormDivider.vue'
import FormInput from '../FormInput.vue'
import { useSiteStore } from '../../store/siteStore'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import CreateTagGroup from './CreateTagGroup.vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const route = useRoute()
const siteStore = useSiteStore()

const isEmpty = computed(
  () => !siteStore.isLoading && siteStore.tagGroups.length === 0,
)

const tagGroupName = computed(() => selectedTagGroup.value.name)

const nameRequired = {
  required: helpers.withMessage(t('validations.emptyField'), required),
}

const rules = {
  tagGroupName: nameRequired,
}

const v$ = useVuelidate(rules, { tagGroupName })

const tagGroupCategory = computed({
  get() {
    return siteStore.tagGroupsCategories.find(
      (category) => category.value === selectedTagGroup.value?.tagCategory,
    )
  },
  set(category) {
    selectedTagGroup.value.tagCategory = category.value
  },
})

const updateTagGroupName = (newName) => {
  selectedTagGroup.value.name = newName
}

const selectedTagGroup = ref(null)
const setActiveEditGroup = (tagGroup) => {
  selectedTagGroup.value = tagGroup
}
const editTagGroup = () => {
  console.log(v$.value.tagGroupName)
  if (!selectedTagGroup.value) {
    return
  }
  v$.value.$touch()
  if (!v$.value.$error) {
    siteStore.editTagGroup(selectedTagGroup.value)
    selectedTagGroup.value = null
  }
}
</script>

<template>
  <!-- More complex logic, but prevents showing dashboard while logging out -->
  <AuthError v-if="authStore.error" />
  <AuthLoading
    v-else-if="!authStore.user || authStore.isLoading || !userVerified"
  />
  <MenuLayout v-else />
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useAuthStore } from './store/authStore'
import { useSettingsStore } from './store/settingsStore'
import { useI18n } from 'vue-i18n'
import MenuLayout from './views/MenuLayout.vue'
import AuthLoading from './components/AuthLoading.vue'
import AuthError from './components/AuthError.vue'
import tooApi from './api/tooPublishers'

const authStore = useAuthStore()
const settingsStore = useSettingsStore()

const { locale } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
// Set initial language
locale.value = settingsStore.locale

const userVerified = ref(false)
const authUser = computed(() => authStore.user)

// Use window.location because useRoute isn't loaded here yet
if (window.location.pathname === '/signup') {
  authStore.signUp()
} else if (window.location.pathname === '/auth/callback') {
  // Moved this outside of AuthCallback component to avoid calling the action multiple times when re-rendering the page during loading
  authStore.handleRedirectCallback()
} else {
  authStore.init()
}

// Check if the user is linked with its publisher before loading any data
//(otherwise vacancies list would be empty in case it's not linked yet)
watch(authUser, async (user) => {
  if (authStore.isAdmin) {
    userVerified.value = true
    return
  }
  if (user) {
    try {
      await tooApi.getUserInfo()
    } catch {
      await tooApi.linkUserAccount()
    } finally {
      userVerified.value = true
    }
  }
})
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
@reference './index.css';

.multiselect {
  @apply mt-1;
}

.multiselect.is-active {
  @apply border-indigo-500 ring-1 ring-indigo-500 outline-hidden;
}

.multiselect-search,
.multiselect-tags-search {
  @apply focus:ring-0;
}

.multiselect-search,
.multiselect-single-label,
.multiselect-option {
  @apply text-sm;
}
.hide-arrows::-webkit-inner-spin-button,
.hide-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-arrows {
  -moz-appearance: textfield;
}
</style>

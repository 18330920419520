<template>
  <Multiselect
    id="companyPublishers"
    ref="multiselect"
    :modelValue="companyStore.publishers"
    :max-height="400"
    :options="
      async function (query) {
        return await fetchPublishers(query)
      }
    "
    :filterResults="false"
    :minChars="0"
    :delay="0"
    searchable
    object
    valueProp="id"
    trackBy="name"
    label="name"
    class="mt-1 block w-full rounded-md border-gray-300 shadow-xs focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
    mode="tags"
    @change="(e) => updatePublishers(e)"
  />
</template>

<script setup>
import { useCompanyStore } from '../../store/companyStore'
import { usePublisherListStore } from '../../store/publisherListStore'
import Multiselect from '@vueform/multiselect'

const companyStore = useCompanyStore()
const publisherListStore = usePublisherListStore()

publisherListStore.page.number = 1
publisherListStore.load()

const updatePublishers = (publishers) => {
  companyStore.publishers = publishers
}

let firstFetch = true
const fetchPublishers = async (query) => {
  query = query || ''
  if (query !== publisherListStore.filter || firstFetch) {
    firstFetch = false
    publisherListStore.filter = query || ''
    await publisherListStore.load()
  }

  return publisherListStore.publishers
}
</script>

<template>
  <dl class="my-3 grid grid-cols-1 gap-5 sm:grid-cols-3">
    <div
      class="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow-sm sm:px-6 sm:pt-6"
    >
      <dt>
        <div class="absolute hidden rounded-md bg-indigo-500 p-3 lg:block">
          <ClipboardDocumentCheckIcon class="h-6 w-6 text-white" />
        </div>
        <p class="truncate text-sm font-medium text-gray-500 lg:ml-16">
          {{ t('candidates.flowType.vacancy-apply') }}
        </p>
      </dt>
      <dd class="flex items-baseline lg:ml-16">
        <p class="min-h-[2rem] text-2xl font-semibold text-gray-900">
          {{ applyTotals }}
        </p>
      </dd>
    </div>

    <div
      class="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow-sm sm:px-6 sm:pt-6"
    >
      <dt>
        <div class="absolute hidden rounded-md bg-indigo-500 p-3 lg:block">
          <EnvelopeIcon class="h-6 w-6 text-white" />
        </div>
        <p class="truncate text-sm font-medium text-gray-500 lg:ml-16">
          {{ t('candidates.flowType.share-by-email') }}
        </p>
      </dt>
      <dd class="flex items-baseline lg:ml-16">
        <p class="min-h-[2rem] text-2xl font-semibold text-gray-900">
          {{ shareByEmailTotals }}
        </p>
      </dd>
    </div>

    <div
      class="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow-sm sm:px-6 sm:pt-6"
    >
      <dt>
        <div class="absolute hidden rounded-md bg-indigo-500 p-3 lg:block">
          <ChatBubbleBottomCenterTextIcon class="h-6 w-6 text-white" />
        </div>
        <p class="truncate text-sm font-medium text-gray-500 lg:ml-16">
          {{ t('candidates.flowType.contact-form') }}
        </p>
      </dt>
      <dd class="flex items-baseline lg:ml-16">
        <p class="min-h-[2rem] text-2xl font-semibold text-gray-900">
          {{ contactTotals }}
        </p>
      </dd>
    </div>
  </dl>
</template>

<script setup>
import { useCandidateListStore } from '../../../store/candidateListStore'
import {
  EnvelopeIcon,
  ClipboardDocumentCheckIcon,
  ChatBubbleBottomCenterTextIcon,
} from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const candidateListStore = useCandidateListStore()

const applyTotals = computed(() => {
  if (!candidateListStore.statistics.statisticsByFlowType) {
    return 0
  }
  const applyStats = candidateListStore.statistics.statisticsByFlowType.find(
    (stats) => stats.flowTypeSlug === 'vacancy-apply',
  )
  return applyStats?.candidateCount || 0
})
const shareByEmailTotals = computed(() => {
  if (!candidateListStore.statistics.statisticsByFlowType) {
    return 0
  }
  const shareStats = candidateListStore.statistics.statisticsByFlowType.find(
    (stats) => stats.flowTypeSlug === 'share-by-email',
  )
  return shareStats?.candidateCount || 0
})
const contactTotals = computed(() => {
  if (!candidateListStore.statistics.statisticsByFlowType) {
    return 0
  }
  const contactStats = candidateListStore.statistics.statisticsByFlowType.find(
    (stats) => stats.flowTypeSlug === 'contact-form',
  )
  return contactStats?.candidateCount || 0
})
</script>

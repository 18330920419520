<template>
  <span class="mx-1 text-gray-200">---></span>

  <div v-if="renameFlowActionActive" class="">
    <label for="flowActionName" class="sr-only">Flow action name</label>
    <input
      id="flowActionName"
      ref="actionNameInput"
      :value="action.name"
      type="text"
      name="flowActionName"
      class="block w-full rounded-md shadow-xs focus:outline-hidden sm:text-sm"
      :class="{
        'border-red-300 pr-10 text-red-900 focus:border-red-500 focus:ring-red-500':
          actionNameEmpty,
        'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500':
          !actionNameEmpty,
      }"
      @input="renameFlowAction"
      @focusout="toggleRenameFlowAction"
    />
    <p v-if="actionNameEmpty" class="absolute mt-0.5 text-sm text-red-600">
      {{ t('candidateGateway.errors.emptyName') }}
    </p>
  </div>
  <div v-else class="relative z-10 inline-flex rounded-md shadow-xs">
    <button
      type="button"
      class="relative inline-flex items-center rounded-l-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-hidden"
      :class="{
        'border-indigo-500 ring-1': isActionActive,
        'border-gray-300': !isActionActive,
        'rounded-r-md': isPreview,
      }"
      @click="setActiveAction"
    >
      <EnvelopeIcon
        v-if="action.flowActionTypeSlug === 'send-email'"
        class="mr-3 -ml-1 w-5"
        aria-hidden="true"
      />

      {{ action.name }}
    </button>
    <Menu v-show="!isPreview" as="span" class="relative -ml-px block">
      <MenuButton
        class="relative inline-flex items-center rounded-r-md border bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 focus:outline-hidden"
        :class="{
          'border-indigo-500 ring-1': isActionActive,
          'border-gray-300': !isActionActive,
        }"
      >
        <span class="sr-only">Open options</span>
        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
      <transition
        enterActiveClass="transition ease-out duration-100"
        enterFromClass="transform opacity-0 scale-95"
        enterToClass="transform opacity-100 scale-100"
        leaveActiveClass="transition ease-in duration-75"
        leaveFromClass="transform opacity-100 scale-100"
        leaveToClass="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden"
        >
          <div class="py-1">
            <MenuItem @click="deleteFlowAction">
              <span
                class="flex cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <TrashIcon
                  v-if="action.flowActionTypeSlug === 'send-email'"
                  class="mr-2 -ml-1 w-5"
                  aria-hidden="true"
                />
                Delete</span
              >
            </MenuItem>
            <MenuItem @click="toggleRenameFlowAction">
              <span
                class="flex cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <PencilIcon
                  v-if="action.flowActionTypeSlug === 'send-email'"
                  class="mr-2 -ml-1 w-5"
                  aria-hidden="true"
                />
                Rename</span
              >
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref, computed, nextTick } from 'vue'
import { useFlowStore } from '../../store/flowStore'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { EnvelopeIcon, PencilIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const props = defineProps({
  action: {
    type: Object,
    required: true,
  },
  flow: {
    type: Object,
    required: true,
  },
  actionIndex: {
    type: Number,
    required: true,
  },
  isPreview: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['renameFlowAction'])

const flowStore = useFlowStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

let actionNameInput = ref(null)
let renameFlowActionActive = ref(false)
let actionNameEmpty = ref(false)

const setActiveAction = () => {
  if (isActionActive.value) {
    flowStore.resetActiveAction()
  } else {
    flowStore.loadAvailableFields(props.flow.id, props.flow?.vacancies)
    flowStore.setActiveAction(props.action, props.actionIndex)
  }
}

const isActionActive = computed(() => {
  return (
    flowStore.activeAction.actionIndex === props.actionIndex &&
    flowStore.activeAction.flowId === props.flow.id
  )
})

const deleteFlowAction = () => {
  flowStore.deleteFlowAction(props.flow, props.action, props.actionIndex)
}

const renameFlowAction = (e) => {
  emit('renameFlowAction', { action: props.action, name: e.target.value })
  if (!e.target.value) {
    flowStore.saveDisabled = true
    actionNameEmpty.value = true
  } else {
    flowStore.saveDisabled = false
    actionNameEmpty.value = false
  }
}

const toggleRenameFlowAction = () => {
  if (actionNameEmpty.value) {
    return
  }
  renameFlowActionActive.value = !renameFlowActionActive.value
  nextTick(() => {
    if (renameFlowActionActive.value) {
      actionNameInput.value.focus()
    }
  })
}
</script>

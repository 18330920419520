<template>
  <Multiselect
    id="defaultFlowForCompanies"
    ref="multiselect"
    :modelValue="flowStore.flowCompanies"
    :max-height="400"
    :options="
      async function (query) {
        return await fetchPublisherCompanies(query)
      }
    "
    :filterResults="false"
    :minChars="0"
    :delay="0"
    searchable
    object
    valueProp="id"
    trackBy="name"
    label="name"
    class="mt-1 block w-full rounded-md border-gray-300 shadow-xs focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
    mode="tags"
    @change="(e) => updateCompanies(e)"
  />
</template>

<script setup>
import Multiselect from '@vueform/multiselect'
import { useFlowStore } from '../../store/flowStore'
import { usePublisherCompanyListStore } from '../../store/publisherCompanyListStore'

const flowStore = useFlowStore()
const publisherCompanyListStore = usePublisherCompanyListStore()

let firstFetch = true
const fetchPublisherCompanies = async (query) => {
  if (firstFetch) {
    firstFetch = false
    await publisherCompanyListStore.load()
  }
  if (isNotBlank(query)) {
    return filterNameIncludes(query)
  }
  return publisherCompanyListStore.companies
}

const isNotBlank = (query) => query && query.trim() !== ''

const filterNameIncludes = (query) => {
  return publisherCompanyListStore.companies.filter((company) =>
    company.name.toLowerCase().includes(query.toLowerCase())
  )
}

const updateCompanies = (companies) => (flowStore.flowCompanies = companies)
</script>

<style scoped></style>

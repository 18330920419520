<template>
  <Multiselect
    id="companySites"
    ref="multiselect"
    :modelValue="companyStore.sites"
    :max-height="400"
    :options="
      async function (query) {
        return await fetchSites(query)
      }
    "
    :filterResults="false"
    :minChars="0"
    :delay="0"
    searchable
    object
    valueProp="siteId"
    trackBy="siteName"
    label="siteName"
    class="mt-1 block w-full rounded-md border-gray-300 shadow-xs focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
    mode="tags"
    @change="(e) => updateSites(e)"
  />
</template>

<script>
import { useSiteListStore } from '../../store/siteListStore'
import { useCompanyStore } from '../../store/companyStore'
import Multiselect from '@vueform/multiselect'

export default {
  name: 'Sites',
  components: { Multiselect },
  setup() {
    const siteListStore = useSiteListStore()
    const companyStore = useCompanyStore()

    siteListStore.page.number = 1
    siteListStore.load()

    const updateSites = (sites) => {
      companyStore.sites = sites
    }

    let firstFetch = true
    const fetchSites = async (query) => {
      query = query || ''
      if (query !== siteListStore.filter || firstFetch) {
        firstFetch = false
        siteListStore.filter = query || ''
        await siteListStore.load()
      }

      return siteListStore.sites.map((t) => ({
        siteId: t.id,
        siteName: t.name,
        internalDomains: '',
        regionalDomains: '',
      }))
    }

    return {
      siteListStore,
      companyStore,
      updateSites,
      fetchSites,
    }
  },
}
</script>

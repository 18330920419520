<template>
  <input
    class="cursor-pointer overflow-hidden border-0 shadow-sm focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 focus:outline-hidden disabled:cursor-not-allowed"
    type="color"
    :value="modelValue"
    @change="handleChange"
  />
</template>

<script setup>
defineProps({
  modelValue: {
    type: String,
    default: '#000000',
  },
})
const emit = defineEmits(['update:modelValue'])

const handleChange = (event) => emit('update:modelValue', event.target.value)
</script>

<style scoped>
/* Remove default browser styles */
input[type='color'] {
  -webkit-appearance: none;
}
input[type='color']::-moz-color-swatch {
  border: none;
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  border: none;
}
</style>
